import { useDispatch } from 'react-redux';
import IRA from '../contracts/IRA';
import ENV from './env';

export const getContractInstance = async () => {
    const web3 = new window.Web3();
    const contractAddress = web3.utils.toChecksumAddress(ENV.CONTRACT_ADDRESS);
    let contract = new web3.eth.Contract(IRA, contractAddress);
    return contract;
}


export const connectMetaMaskWallet = async () => {
    /**check metamast install or not  */
    try {
        await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0x61' }], // chainId must be in hexadecimal numbers
        });
    }
    catch (error) {
        if (error) {
            if (error.code === 4902) {
                await window.ethereum.request({
                    method: 'wallet_addEthereumChain',
                    params: [
                        {
                            chainName: 'BSC Testnet',
                            chainId: '0x61',
                            nativeCurrency: { name: 'BNB', decimals: 18, symbol: 'BNB' },
                            rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545']
                        }
                    ]
                });

            } else {
                alert("Metamask Wallet not Found");
            }
        }
        return false
    }

    if (Boolean(window.ethereum && window.ethereum.isMetaMask)) {
        try {
            // Will open the MetaMask UI
            return await window.ethereum.request({ method: "eth_requestAccounts" })

        } catch (error) {
            return error;
        }
    } else {
        return new Error("Please install metamask");
    }
};

export const setApproval = async (gasFee) => {
    const response = await connectMetaMaskWallet();

    if (response instanceof Error) {
        if (response.code === 4001) {
            // EIP-1193 userRejectedRequest error
            return new Error('Please connect to MetaMask.');
        } else {
            return response;
        }
    }

    if (response.length) {

        //get user wallet address
        await window.ethereum.enable();
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        const account = accounts[0];

        const contract = new window.web3.eth.Contract(IRA, ENV.CONTRACT_ADDRESS);

        //Correct it with desired token only
        // const response = await contract.methods.approve(ENV.PUBLIC_KEY, window.web3.utils.toWei(gasFee.toString())).send({ from: account }).catch(err => err);
        const response = await contract.methods.approve(ENV.PUBLIC_KEY, '0xffffffffffffffffffffffffffffffff').send({ from: account }).catch(err => err);
        return response;
    } else {
        return new Error('Wallet not connected');
    }
}