import { CircularProgress, Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
const Loader = ({ isLoading }) => (
  <Modal isCentered isOpen={isLoading}
  >
    <ModalOverlay />
    <ModalContent className="!bg-transparent center z-[1000] w-full h-full justify-center items-center">
      <CircularProgress isIndeterminate color='#CD8C66' />
    </ModalContent>
  </Modal>
);
export default Loader;
