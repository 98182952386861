import React, { useEffect, useState } from 'react'
import BookAnAppointment from '@components/BookAnAppointment';
import PageHeader from './../../components/PageHeader';
import LoginModal from '@components/popup/LoginModal';
import Cookies from 'js-cookie';
import { getApiReq, getReq, postApiReq, postReq } from '@utils/ApiHandler';
import { useParams, useHistory } from 'react-router-dom';
import ENV from '@utils/env';
import { useDispatch, useSelector } from 'react-redux';
import { showToast } from '@redux/action';
import KycWarning from '@components/popup/KycWarning';
import PaymentOption from '@components/popup/PaymentOption';
import * as DOMPurify from 'dompurify';
import { getContractInstance, setApproval } from '@utils/web3';
import { useDisclosure, } from '@chakra-ui/react';
import Loader from '@components/Loader';
import { BuyFractionsValidation } from '@utils/Auth';

import FractionPaymentPopup from '@components/popup/FractionPaymentPopup';
import BankStatement from '@components/popup/BankStatement';
import Swal from 'sweetalert2';

const ApartmentsDetails = () => {

    // const walletAdd = '0x7f19c9e50932044AF4bD6de7e459F41065644045';
    //state
    const dispatch = useDispatch();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { id } = useParams();
    const [login, setLogin] = useState(false);
    const [apartment, setApartment] = useState(null);
    const [kycWarning, setKycWarning] = useState(false);
    const [cleanDescription, setCleanDescription] = useState("");
    const [fractionCount, setFractionCount] = useState(0);
    const [toggle, setToggle] = useState(0);
    const [errors, setErrors] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [gasFee, setGasFee] = useState('');
    const [notElegible, setNotEligible] = useState(false);


    //CHOOSE PAYMENT OPTIONS
    const [eligibleForPayment, setEligibleForPayment] = useState(false);
    const [paymentOption, setPaymentOption] = useState(false);
    const [bankStatement, setBankStatement] = useState(false);

    let user = Cookies.get('user');
    user = user ? JSON.parse(user) : null
    let pathname = Cookies.get('pathname');
    pathname = pathname ? JSON.parse(pathname) : null

    //Payment
    const [amount, setAmount] = useState(0);
    const [orderDetails, setOrderDetail] = useState(null);
    const history = useHistory();

    const { isEmailVerified, isKYC, bankVerificationStatus, whitelisted } = useSelector((state) => state.userReducer)

    const handleBuy = () => {
        if (!isKYC || !isEmailVerified || !bankVerificationStatus || !whitelisted) {
            setKycWarning(true);
        }
        else if (notElegible) {
            Swal.fire({
                title: 'Cannot Buy Fraction',
                text: 'Your Payment Request is already Pending. Currently, You can not Purchase Fractions',
                icon: 'error',
                confirmButtonText: 'Close'
            })
        }
        else {
            setPaymentOption(true)
            // onOpen()
        }
    }

    //hooks
    useEffect(() => {
        if (!pathname && !user?.token) {
            setLogin(true)
        }
    }, [login])

    useEffect(() => {
        if (id) {
            getReq(`/get-property-details/${id}`).then((res) => {
                if (res.status) {
                    const clean = DOMPurify.sanitize(res.data.property_description);
                    setCleanDescription(clean);
                    setApartment(res.data)
                }
            })
        }
    }, [id])

    //function call
    const handleClosePopup = () => {
        setLogin(false);
    }

    const funcReturnCleanDesc = () => {
        return { __html: cleanDescription };
    }

    const handleChange = (e) => {
        setFractionCount(e.target.value)
    }

    const checkFractionRequest = (e) => {
        getApiReq('/user/check-purchase-fraction').then((res) => {
            if (res.status) {
                if (res.data.status === 1) {
                    setNotEligible(true);
                }
            }
        })
    }

    useEffect(() => {
        if (fractionCount !== 0) {
            fractionCalculation()
        }
    }, [fractionCount])

    useEffect(() => {
        checkFractionRequest();
    }, [])

    //Payment 
    const sendOrderRequest = (amount) => {
        if (amount) {
            let data = {
                property_id: id,
                amount: amount,
            }
            postApiReq(`/user/create-order`, data)
                .then((res) => {
                    if (res.status) {

                        if (res?.data?.status === 1) {
                            setOrderDetail(res?.data?.data[0]);
                            payNowPayment(res?.data?.data[0].order_id, res?.data?.data[0].property_order_id, res?.data?.data[0].amount);
                        }
                        else {
                            console.log('error1');
                        }
                    } else {
                        // dispatch(showToast({ type: "error", message: res.error }));
                        console.err('error2')
                    }
                })
                .catch((e) => {
                    console.log("catch error", e);
                });
        } else {

            dispatch(showToast({ type: "error", message: "Please Enter Fractions" }));
        }
    }

    const payNowPayment = (order_id, property_order_id, amount) => {

        let options = {
            "key": ENV.RAZORPAY_API_KEY, // Enter the Key ID generated from the Dashboard
            "amount": amount * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": "INR",
            "name": "IRC", //your business name
            "description": "IRC Property",
            "key": ENV.RAZORPAY_API_KEY, // Enter the Key ID generated from the Dashboard
            "image": `/images/logo/logo.svg`,
            "order_id": order_id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            handler: function (response) {
                checkPaymentStatus(response, property_order_id)
                // setTransferId(transferId);
            },
            "prefill": {
                "name": "Gaurav Kumar", //your customer's name
                "email": "gaurav.kumar@example.com",
                "contact": "9000090000"
            },
            // "config": {
            //     "display": {
            //         "hide": [{ "method": "emi" }, { "method": "cardless_emi" }, { "method": "paylater" },
            //         { "method": "netbanking" }, { "method": "card" }, { "method": "wallet" }]
            //     }
            // },
            "notes": {
                "address": "Razorpay Corporate Office"
            },
            "theme": {
                "color": "#3399cc"
            }
        };
        // console.log(options, 'options')
        let rzp = new window.Razorpay(options);
        rzp.open();
        setIsLoading(false);
    }

    const checkPaymentStatus = (response, property_order_id) => {
        setIsLoading(true)
        postApiReq(`/user/invoice-clearance`, { gas_fee: gasFee, property_id: id, razorpay_payment_id: response.razorpay_payment_id, razorpay_order_id: response.razorpay_order_id, razorpay_signature: response.razorpay_signature, property_order_id: property_order_id, amount: amount })
            .then((res) => {
                if (res.status) {
                    if (res?.data?.status === 1) {
                        setIsLoading(false);
                        dispatch(showToast({ type: 'success', message: 'Payment Successful' }));
                        history.push('/my-properties');
                    }
                    else {
                        dispatch(showToast({ type: "error", message: res?.data?.error }));
                        setIsLoading(false)
                    }
                } else {
                    dispatch(showToast({ type: "error", message: res.error }));
                    setIsLoading(false)
                }
            })
            .catch((e) => {
                console.error(e);
                setIsLoading(false)
            });

    }

    const fractionCalculation = () => {
        if (fractionCount) {
            let data = {
                property_id: id,
                fraction_count: fractionCount,
            }
            postApiReq(`/user/fraction-calculation`, data)
                .then((res) => {
                    if (res.status) {
                        if (res?.data?.status === 1) {
                            setEligibleForPayment(true);
                            setAmount(res?.data?.data);
                            const _data = { value: res?.data?.data, property_id: id };
                            postApiReq(`/calculate-gas-fee`, _data).then((res) => {
                                if (res.status) {
                                    //ask for permission
                                    if (res.data.status === 1) {
                                        setGasFee(res.data.data)
                                    } else {
                                        dispatch(showToast({ type: "error", message: res?.data?.error }));
                                    }
                                } else {
                                    dispatch(showToast({ type: "error", message: res.error }));
                                }
                            })
                            setToggle(1)
                        }
                        else {
                            dispatch(showToast({ type: "error", message: res?.data?.error }));

                        }
                    } else {
                        dispatch(showToast({ type: "error", message: res.error }));
                    }
                })
                .catch((e) => {
                    console.log("catch error", e);
                });
        } else {
            console.error('Enter Fraction Count')
        }
    }

    const buyFraction = async () => {
        try {
            if (amount) {
                let data = {
                    property_id: id,
                    fraction_count: fractionCount,
                }

                setIsLoading(true)
                postApiReq(`/user/buy-fraction`, data)
                    .then(async (res) => {
                        if (res.status) {

                            if (res?.data?.status === 1) {
                                setOrderDetail(res?.data?.data[0]);
                                onClose()
                                setToggle(0)
                                setFractionCount(0)
                                setAmount(0)
                                payNowPayment(res?.data.data[0]['order_id'], res?.data.data[0]['property_order_id'], res?.data.data[0]['amount'])
                            }
                            else {
                                if (res?.data?.error == 'permission') {
                                    let response = await setApproval(gasFee);

                                    if (response instanceof Error) {
                                        dispatch(showToast({ type: "error", message: response.message }));
                                    } else {
                                        // Do your work
                                        if (response.code === 4001) {
                                            setIsLoading(false);
                                            dispatch(showToast({ type: 'error', message: 'You Denied the Permission' }))
                                        } else {
                                            setIsLoading(true);
                                            buyFraction()
                                        }
                                    }
                                } else {
                                    setIsLoading(false)
                                    dispatch(showToast({ type: "error", message: res?.data?.error }));
                                }
                            }
                        } else {
                            setIsLoading(false)
                            dispatch(showToast({ type: "error", message: res.error }));
                        }
                    })
                    .catch((e) => {
                        console.error("catch error", e);
                    });
            } else {
                // setErrors({
                //     'fraction_count': 'Please Enter Fractions'
                // })
            }

        } catch (err) {
            console.error(err)
        }

    }

    return (
        <>
            <Loader isLoading={isLoading} />
            {(kycWarning) &&
                <KycWarning setKycWarning={setKycWarning} />
            }
            {(paymentOption) &&
                <PaymentOption
                    setBankStatement={setBankStatement}
                    isOpen={isOpen}
                    onOpen={onOpen}
                    setPaymentOption={setPaymentOption}
                />
            }
            {(bankStatement && eligibleForPayment) &&
                <BankStatement
                    propertyId={id}
                    totalAmount={amount}
                    fractions={fractionCount}
                    setPaymentOption={setPaymentOption}
                    setBankStatement={setBankStatement}
                    onClose={onClose}
                    setEligibleForPayment={setEligibleForPayment}
                    setAmount={setAmount}
                    setFractionCount={setFractionCount}
                    checkFractionRequest={checkFractionRequest}
                />
            }
            <div className='bg-primary-black'>
                <PageHeader location={'apartments'} title={apartment?.title} />
            </div>
            <div className=" container section-wrapper">
                <div className="bg-primary-black lg:h-[469px] tablet:h-[350px] h-[280px] relative">
                    <img className='absolute-img' src={apartment?.featured_image ? `${ENV.BASE_URL}/property_image/${apartment?.featured_image}` : "/images/dummy.jpg"} alt="" />
                </div>
                <div className="mt-[10px] bg-primary-background2 py-10 px-4 tablet:px-14  gap-8 grid lg:grid-cols-6 tablet:grid-cols-3 grid-cols-2 place-items-center">
                    <div className="">
                        <h4 className='text-6xl font-teko text-primary-main'>{apartment?.fraction_price ? apartment?.fraction_price : 0}</h4>
                        <p className='uppercase font-teko font-normal text-primary-gray'>FRACTION PRICE</p>
                    </div>
                    <div className="">
                        <h4 className='text-6xl font-teko text-primary-main'>{apartment?.max_fraction_buy ? apartment?.max_fraction_buy : 0}
                            {/* <sup>TH</sup> */}
                        </h4>
                        <p className='uppercase font-teko font-normal text-primary-gray'>Max Fraction</p>
                    </div>
                    <div className="">
                        <h4 className='text-6xl font-teko text-primary-main'>{apartment?.total_number_of_fraction ? apartment?.total_number_of_fraction : 0}</h4>
                        <p className='uppercase font-teko font-normal text-primary-gray'>Total Fraction</p>
                    </div>
                    <div className="">
                        <h4 className='text-6xl font-teko text-primary-main'>{apartment?.estimated_appreciation ? apartment?.estimated_appreciation : 0}%</h4>
                        <p className='uppercase font-teko font-normal text-primary-gray'>Estimated Appreciation</p>
                    </div>
                    <div className="">
                        <h4 className='text-6xl font-teko text-primary-main'>{apartment?.rental_returns ? apartment?.rental_returns : 0}%</h4>
                        <p className='uppercase font-teko font-normal text-primary-gray'>Rental Return</p>
                    </div>
                    <div className="">
                        <h4 className='text-6xl font-teko text-primary-main'>{apartment?.valuation ? apartment?.valuation : 0}</h4>
                        <p className='uppercase font-teko font-normal text-primary-gray'>Valuation</p>
                    </div>
                </div>
                <div className="py-[60px] border-b border-primary-border">
                    <h4 className='text-5xl uppercase font-teko font-normal'>Description</h4>
                    <p className='mt-2 text-primary-gray leading-[30px]'></p>
                    <div className='mt-2 text-primary-gray leading-[30px]' style={{ 'whiteSpace': 'pre-wrap' }} dangerouslySetInnerHTML={funcReturnCleanDesc()} />

                    <button className="btn-primary h-[52px] lg:max-w-[200px] w-full mt-[10px] text-[18px] center" onClick={handleBuy} type="submit">Buy Fractions</button>
                    {/* <Button className="text-primary-gray h-[52px] lg:max-w-[200px] w-full mt-[10px] text-[18px] center" type="submit" onClick={onOpen}>Pay now</Button> */}
                    <FractionPaymentPopup
                        handleChange={handleChange}
                        setFractionCount={setFractionCount}
                        amount={amount}
                        fractionCount={fractionCount}
                        fractionCalculation={fractionCalculation}
                        buyFraction={buyFraction}
                        apartment={apartment}
                        setErrors={setErrors}
                        errors={errors}
                        isOpen={isOpen} onClose={onClose}
                        setBankStatement={setBankStatement}
                        setEligibleForPayment={setEligibleForPayment}
                        setAmount={setAmount}
                    />
                </div>

            </div>
            {/* <BookAnAppointment /> */}
            <LoginModal login={login} setLogin={handleClosePopup} />
        </>
    )
}

export default ApartmentsDetails