import * as yup from 'yup';

export const SignupValidation = yup.object().shape({
  full_name: yup.string().required('Please enter full name.')
    .matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed')
    .max(30, 'Maximum 30 characters are allowed.'),
  email: yup.string().required('Please enter email.')
    .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Please enter valid email.'),
  phone: yup.string().required('Please enter phone number.')
    .matches(/^[0-9]{10}$/, 'Only numbers are acceptable'),
  password: yup.string().required('Please enter password')
    .matches(/^(?=.*[a-z])(?!.* )(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/, 'Only accept One Uppercase and one lowercase and atleast one special characters and numbers.')
    .min(8, 'Minimum 8 characters is required.'),
});

export const LoginValidation = yup.object().shape({
  email: yup.string().required('Please enter valid email.')
    .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Please enter valid email.'),
  password: yup.string().required('Please enter password')
})

export const ForgotPasswordValidation = yup.object().shape({
  email: yup.string().required('Please enter email.')
    .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Please enter valid email.'),
})

export const ResetPasswordValidation = yup.object().shape({
  password: yup.string().required('Please enter password')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/, 'Only accept One Uppercase and one lowercase and atleast one special characters and numbers.')
    .min(8, 'Minimum 8 characters are required.'),
  confirmPassword: yup.string().required('Please enter password')
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]/, 'Only accept One Uppercase and one lowercase and atleast one special characters and numbers.')
    .min(8, 'Minimum 8 characters are required.'),
})

export const UpdateProfileValidation = yup.object().shape({
  fullName: yup.string().required('Please enter full name.')
    .matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed')
    .max(30, 'Maximum 30 characters are allowed.'),
  email: yup.string().required('Please enter valid email.')
    .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Please enter valid email.'),
  phone: yup.string().required('Please enter phone number.')
    .matches(/^[0-9]{10}$/, 'Only numbers are acceptable')
})

export const EmailValidation = yup.object().shape({
  email: yup.string().required('Please enter valid email.')
    .matches(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Please enter valid email.')
})

export const MobileValidation = yup.object().shape({
  phone: yup.string().required('Please enter phone number.')
    .matches(/^[0-9]{10}$/, 'Please Enter 10 Digit Valid number')
})

export const PancardValidation = yup.object().shape({
  pancard: yup.string().required('Please Enter Pancard Number')
    .matches(/([A-Z]){5}([0-9]){4}([A-Z]){1}$/, 'Please Enter Valid Pancard')
})

export const AadharValidation = yup.object().shape({
  aadharCard: yup.string().required('Please Enter Your Aadhar')
    .matches(/^[0-9]{12}$/, 'Please Enter Valid Aadhar')
})

export const AddBankDetailsValidation = yup.object().shape({
  accountHolderName: yup.string().required('Please Enter Account Holder Name.')
    .matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed')
    .max(30, 'Maximum 30 characters are allowed.'),
  bankName: yup.string().required('Please Enter Bank Name.')
    .matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed'),
  accountNo: yup.string().required('Please Enter Account Number.')
    .matches(/^[0-9]/, 'Only Numbers are allowed'),
  bankIfsc: yup.string().required('Please Enter Bank IFSC.')
    .matches(/^[a-z0-9]+$/i, 'Please Enter Valid IFSC'),
  branchName: yup.string().required('Please Enter Branch Name.'),
  cheque: yup.string().required('Please Choose Cheque ')
})


export const BuyFractionsValidation = yup.object().shape({
  fraction_count: yup.string().required('Please Enter Fractions')
})

export const addPaymentRequest = yup.object().shape({
  senderName: yup.string().required('Please enter Sender Name.').nullable()
    .matches(/^[A-Za-z\s]+$/, 'Only alphabets are allowed'),
  senderBankAccount: yup.string().required('Please Enter Sender Bank Account Number')
    .matches(/[0-9]/, 'Only numbers are acceptable.'),
  senderBankName: yup.string().required('Please Enter Bank Name')
    .matches(/^[A-Za-z\s]+$/, 'Please Enter Valid Bank Name'),
  transactionDescription: yup.string().required('Please Enter Transaction Details')
})