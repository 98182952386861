import { SET_USER_DETAILS } from "@redux/action/actionConstants";

const initialState = {
  username: '',
  email: '',
  phone: '',
  isEmailVerified: true,
  isPhoneVerified: false,
  isKYC: false,
  aadharCard: null,
  pancard: null,
  bankVerificationStatus: false,
  updateDetails: false,
  whitelisted: false,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DETAILS:
      return { ...state, ...action.payload }
    default:
      return state;
  }
  //   case SET_VERIFIED: 
  //     return {
  //       // ...state,
  //       isVerified: action.payload
  //     }
  //   default:
  //     return state;
  // }
};

export default UserReducer;
