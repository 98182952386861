import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';
import { icons } from '@utils/icons';
import React from 'react'

const SearchModal = ({ isSearch, setIsSearch }) => {
  return (
    <>
      <Modal
        isCentered
        onClose={() => setIsSearch(false)}
        size={'xl'}
        isOpen={isSearch}
        motionPreset='slideInBottom'
      >
        <ModalOverlay />
        <ModalContent className='!mx-4 '>
          <ModalBody className='!p-0 rounded-md overflow-hidden'>
            <div className='relative'>
              <input type="text" placeholder='search here  ' />
            </div>

          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  )
}

export default SearchModal