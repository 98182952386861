import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box } from '@chakra-ui/react';
import React from 'react'
import { useState } from "react"
import PageHeader from './../../components/PageHeader';
import { icons } from '@utils/icons';
import { faq } from '@utils/constants'

const Faq = () => {

    const [selected, setSelected] = useState(null)
    const toggle = (i) => {
        if (selected == i) {
            return setSelected(null)
        }
        setSelected(i)
    }

    return (
        <>
            <PageHeader location={'FAQS'} title={'FAQS'} />
            <section className='section-wrapper'>
                <div className="container">
                    <Accordion className='grid  grid-cols-1 max-w-4xl mx-auto gap-5 md:gap-[30px]' allowMultiple>
                        {faq.map((item, index) => (
                            <AccordionItem key={index}>
                                <h2>
                                    <AccordionButton _focus={{ boxShadow: 'none' }} className='justify-between border py-[20px] ' paddingY="20px" onClick={() => toggle(index)}>
                                        <div className={`heading-6 text-left font-teko uppercase ${selected == index ? `text-primary-main` : `text-primary-black`} `}>
                                            {item?.question}
                                        </div>
                                        {selected != index ? (
                                            <img src="/images/plus.svg" alt="" />
                                        ) : (
                                            <span className=''></span>
                                        )}
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel className='border border-primary-border' pb={4}>
                                    {item?.answer}
                                </AccordionPanel>
                            </AccordionItem>
                        ))}
                    </Accordion>
                </div>
            </section>
        </>
    )
}

export default Faq