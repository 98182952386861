import ProductCard from '@components/cards/ProductCard'
import Categories from '@components/Categories'
import PageHeader from '@components/PageHeader'
import ReactCustomSelect from '@components/ReactCustomSelect'
import { getReq } from '@utils/ApiHandler'
import React, { useEffect, useState } from 'react'

const Products = () => {
    const [apartment, setApartment] = useState(null);
    const sortby = [
        { label: 'Sort by Categories', value: 'Sort by Categories' },
        { label: 'Economic', value: 'economic' },
        { label: 'Comfort', value: 'comfort' },
        { label: 'VIP', value: 'vip' },
    ]

    useEffect(() => {
        getReq('/get-all-properties').then((res) => {
            if (res.status) {
                let image = res.data[0].property_images.split(',')
                setApartment(res.data)
            }
        })
    }, [])

    return (
        <div>
            <div className="bg-[url('../public/images/invest.jpg')] bg-cover bg-no-repeat bg-left relative">
                <div class="absolute inset-0 bg-black opacity-40 "></div>
                <PageHeader location={'shop'} title='Commercial Opportunities' />
            </div>

            <section className="section-wrapper" >
                <div className="container">
                    <div className='flex flex-col tablet:flex-row gap-6'>
                        <div className='w-full'>
                            <div className="flex tablet:flex-row flex-col justify-between tablet:items-center gap-4">
                                {/* <span className='text-primary-gray'>Showing 1–9 of 12 results</span> */}
                                <div className='tablet:max-w-[250px] max-w-full lg:max-w-[340px] w-full'>
                                    {/* <ReactCustomSelect option={[...sortby]} /> */}
                                </div>
                            </div>
                            <div className="grid tablet:grid-cols-2 grid-cols-1 lg:grid-cols-3 gap-8 mt-8">
                                {apartment?.map((item, index) => (
                                    <ProductCard key={index} appartment={item} />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Products