export const navlinks = [
  { title: "Home", path: '/' },
  { title: "About", path: '/about' },
  { title: "Commercial Opportunities", path: '/commercial-properties' },
  // { title: "Apartments", path: '/apartments' },
  // { title: "News", path: '/news' },
  // { title: "Shop", path: '/products' },
  { title: "Contacts", path: '/contact' },

];

export const apartment = [
  {
    imgpath: "/images/residential.png",
    name: "SMART LIVING",
    para: "There are many new  ways of text.",
  },
  {
    imgpath: "/images/sustainable.png",
    name: "Eco construction",
    para: "There are many new  ways of text.",
  },
  {
    imgpath: "/images/world.png",
    name: "good location",
    para: "There are many new  ways of text.",
  },
  {
    imgpath: "/images/home-automation.png",
    name: "High technology",
    para: "There are many new  ways of text.",
  },
  {
    imgpath: "/images/electric.png",
    name: "luxury appliance",
    para: "There are many new  ways of text.",
  },
  {
    imgpath: "/images/swimming.png",
    name: "swimming pool",
    para: "There are many new  ways of text.",
  }
];
export const valuess = [
  {
    imgpath: "/images/checked.png",
    name: "wellness",

  },
  {
    imgpath: "/images/checked.png",
    name: "fitness",
  },
  {
    imgpath: "/images/checked.png",
    name: "shopping",
  },
  {
    imgpath: "/images/checked.png",
    name: "conference",
  },
  {
    imgpath: "/images/checked.png",
    name: "library",
  },
  {
    imgpath: "/images/checked.png",
    name: "fashion",
  },
  {
    imgpath: "/images/checked.png",
    name: "restaurant",
  },
  {
    imgpath: "/images/checked.png",
    name: "bars",
  }
];

export const building = [
  {
    imgpath: '/images/buildicon1.png'
  },
  {
    imgpath: '/images/buildicon2.png'
  },
  {
    imgpath: '/images/buildicon3.png'
  },
  {
    imgpath: '/images/buildicon4.png'
  },
  {
    imgpath: '/images/buildicon5.png'
  },
  {
    imgpath: '/images/buildicon6.png'
  }
];

export const property = [
  {
    imgpath: '/images/Outline1.png',
    title: <h4 className='heading-4 uppercase '>Smart & High tech <br /> living style</h4>,
    para: 'Aliq is notm hendr erit a augue insu image pellen tes.',
    btn: 'READ MORE'
  },
  {
    imgpath: '/images/Outline2.png',
    title: <h4 className='heading-4 uppercase '>Attractive building <br /> location</h4>,
    para: 'Aliq is notm hendr erit a augue insu image pellen tes.',
    btn: 'READ MORE'
  },
  {
    imgpath: '/images/Outline3.png',
    title: <h4 className='heading-4 uppercase '>Eco & Luxury building<br />construction</h4>,
    para: 'Aliq is notm hendr erit a augue insu image pellen tes.',
    btn: 'READ MORE'
  }
];

export const outstanding = [
  {
    title: 'SMART LIVING',
    imgpath: '/images/checked.png',
    p1: 'Wake-up call',
    p2: 'Sightseeing',
    p3: 'Car hire, Bicycle hire',
    p4: 'Laundry and Cleaning',
    p5: 'Own parking Space',
  },
  {
    title: 'All amenities',
    imgpath: '/images/checked.png',
    p1: 'Room Service',
    p2: 'Allergy Friendly Rooms',
    p3: 'Air conditioning',
    p4: 'Coffee and Teas',
    p5: 'Free Wi-Fi in all Rooms',
  },
  {
    title: 'Modern technology',
    imgpath: '/images/checked.png',
    p1: 'Wake-up call',
    p2: 'Sightseeing',
    p3: 'Car hire, Bicycle hire',
    p4: 'Laundry and Cleaning',
    p5: 'Own parking Space',
  },
  {
    title: 'Attractive location',
    imgpath: '/images/checked.png',
    p1: '24-hour Reception',
    p2: 'Check in-out',
    p3: 'Breakfast Buffet',
    p4: 'Lunch from 11.00',
    p5: 'Dinner from 17.30',
  },

]

export const faq = [
  {
    question: "What is Brickschain Group and what are its businesses?",
    answer: <p>Brickschain is a technology group focused on developing solutions in the blockchain space and offers a range of services including consulting, research, development, and deployment of blockchain systems. The group is dedicated to helping businesses and organizations optimize and integrate blockchain technology into their operations. Brickschain is committed to driving the adoption of blockchain and delivering cutting-edge solutions that address the challenges and needs of its clients.<br /><br />Brickschain is planning to offer fractional shares to make investments in valuable real estate assets and this makes investing in real estate more accessible and affordable for a wider range of investors. This opens investment opportunities that were previously unavailable to smaller investors and provides a more inclusive and democratized approach to investing. Brickschain's goal is to make it easier for individuals to invest in and benefit from valuable assets, regardless of their financial standing.</p>
  },
  {
    question: "What is an SPV?",
    answer: <p>An SPV, or Special Purpose Vehicle, is a separate legal entity created to carry out a specific and limited purpose. In the context of Brickschain, an SPV is established for each real estate investment, so that the investors have full control and ownership of the investment and are protected from any potential financial risks associated with Brickschain. Brickschain will provide management and legal compliance services for the asset, but the financial control and ownership rests with the investors.</p>
  },
  {
    question: "What is fractional real estate investment?",
    answer: <p>Fractional real estate investment involves owning a portion of a real estate property through the ownership of company shares (the SPV in this case). Instead of owning the entire property, multiple investors come together to legally share the ownership, each owning a fraction of the asset. As a result, each investor earns a proportional share of the monthly rental income and benefits from any appreciation in the property's value. This investment model offers a convenient and cost-effective way for individuals to invest in valuable real estate assets.</p>
  },
  {
    question: "Is fractional ownership risky?",
    answer: <p>Fractional ownership is an attractive investment option as it offers a balance between low risk and high potential returns. Although all investments carry some level of risk, fractional ownership presents a relatively lower risk compared to other investment options with similar return expectations. The Brickschain group leverages machine learning and blockchain technology to enhance the transparency of information and simplify the investment process, thereby reducing the inherent risks associated with investing. By doing so, Brickschain aims to make fractional ownership a more accessible and secure investment opportunity for a wider range of individuals.</p>
  },
  {
    question: "What is the process followed when acquiring an asset?",
    answer: <p>At Brickschain, the listing of properties is a crucial aspect of our operations. We employ a comprehensive and data-driven approach to asset selection, ensuring that their investors receive the maximum return on their investments. The company has a strict due diligence and evaluation process that only allows 2% of the properties analyzed to be listed on our platform. This rigorous process ensures that only high-quality, valuable assets are available for investment through Brickschain, maximizing the potential for returns. To learn more about Brickschain's process for property listing and investment, please visit us at 'How it Works' section of the website at _________________.</p>
  },
  {
    question: "What KYC documents are needed to sign up?",
    answer: <p>Brickschain has a fully digital Know Your Customer (KYC) process for its investors. In order to complete the KYC process, individuals must provide scans or images of their Aadhar Card and PAN card and register their mobile number with the company. This streamlined and convenient process makes it easier for individuals to invest with Brickschain, while also ensuring compliance with regulatory requirements.</p>
  },
  {
    question: "Do you have a mobile app?",
    answer: <p>No, Brickschain currently does not have a mobile app. The company instead focuses on providing a secure and transparent platform for investment through their website. This allows for easy validation of ownership documents and ensures the protection of sensitive financial information.</p>
  },
  {
    question: "What are the risks associated with these investments?",
    answer: <p>Fractional real estate investment through Brickschain is considered a relatively low-risk option. However, there are risks associated with the investment, such as fluctuations in property prices and changes in rental agreements. To minimize these risks, Brickschain carefully selects assets that are of high quality and have strong potential for growth. This includes Grade A buildings with established tenants, favorable contracts, and long commitment periods to provide consistent monthly returns for investors. Through a thorough due diligence and management process, Brickschain works to mitigate common risks associated with fractional investments, such as liquidity, tenant, and market risks. The goal is to provide their investors with a secure and profitable investment opportunity.</p>
  },
  {
    question: "What kind of returns can I expect?",
    answer: <p>Investors can expect to see an estimated yearly rental return of 8-10% on the assets listed by Brickschain. Additionally, the company projects an Internal Rate of Return (IRR) of 12-20% over a period of 5 years for these investments. These projections are based on Brickschain's rigorous asset selection and management processes, as well as the high-quality nature of the properties they list. However, as with any investment, past performance is not a guarantee of future results, and the actual returns may differ from these projections.</p>
  },
  {
    question: "What happens if a property fails to be fully funded?",
    answer: <p>If a property listed on Brickschain's platform fails to reach its funding target, the company will reimburse the funds of the investors to their registered bank account. This means that if a property does not receive sufficient investment to move forward, the individuals who invested in that property will receive their funds back. This policy provides a measure of protection for investors and helps to ensure a secure investment process.</p>
  },
  {
    question: "How can I get specific questions answered on a particular property listing on the platform?",
    answer: <p>If you have specific questions about a property listing on Brickschain's platform, you can reach out to our customer support team for assistance. To do so, simply fill out an inquiry form with your name, contact number, and email address. A representative from Brickschain will then get in touch with you to answer your questions and address any concerns you may have.</p>
  },
  {
    question: "Who can invest with Brickschain?",
    answer: <p>Brickschain's investment platform, is open to a variety of entities, including Indian citizens, Non-Resident Indians (NRIs), companies (including private limited companies and proprietorships), Hindu Undivided Families (HUFs), trusts, and cooperative societies. To invest, individuals must complete a valid Know Your Customer (KYC) process and comply with regulatory guidelines.<br /><br />NRIs may only invest through a Non-Residential Ordinary (NRO) account or a normal savings bank account in India. Please note that these investments will be classified as Foreign Direct Investment (FDI). In case you need any assistance with compliances, we can direct you to any Chartered Accountant, or you may seek help from any consultant of your choice.</p>
  },
  {
    question: "How do I get started as an investor?",
    answer: <p>You can register on our platform by clicking on 'Sign up'. Alternatively, you can click on 'Enquire now' and our experts will guide you through the registration process. Once registered, you will be able to see all the details of the assets listed on our platform.</p>
  },
  {
    question: "Will I need any legal support to make an investment?",
    answer: <p>When investing with Brickschain, we will handle all necessary legal aspects of the property purchase, making the process digital and transparent. All related documents will be available online through your account. However, if you prefer to have your own legal support, we are willing to provide additional information.</p>
  },
  {
    question: "How do you keep my personal information secure?",
    answer: <p>We take the security of personal information very seriously and have implemented measures to protect the confidentiality and privacy of all information. Our platform uses 256-bit SHA encryption to secure sensitive investor data, and we have strict Non-Disclosure and Confidentiality policies in place to protect your information from unauthorized access.</p>
  },
  {
    question: "What is the minimum investment?",
    answer: <p>The minimum investment amount is Rs. 1,00,000 and in multiples of Rs. 1,00,000.</p>
  },
  {
    question: "What happens if a property fails to be fully funded?",
    answer: <p>If a property fails to be fully funded, the investors' funds will be returned to their registered bank account.</p>
  },
  {
    question: "Will I need to travel to the location of the property or such?",
    answer: <p>Our platform is designed to make the investment process as convenient and hassle-free as possible for our investors, so you don't have to physically visit the property location or travel for any documentation procedures. Everything can be done digitally.</p>
  },
  {
    question: "Do you offer any guarantee on returns?",
    answer: <p>It is important to note that investment in real estate and property carries a degree of risk and there is no guarantee of returns. However, we strive to provide a data-driven and stringent due diligence process to mitigate potential risks and increase the chances of consistent and profitable returns for investors.</p>
  },
  {
    question: "What are the risks associated with these investments?",
    answer: <p>Some risks associated with the investment could include changes in property prices or renegotiations of rentals. It is important to conduct thorough research and understand the risks involved before making an investment decision.</p>
  },
  {
    question: "What sort of returns can I expect?",
    answer: <p>The expected IRR for investments with Brickschain is 13-20% over a period of 5 years. However, we do not guarantee returns and it is important for investors to be aware of and understand the risks involved in any investment.</p>
  },
  {
    question: "Is there one payout at the end of each year? What is the IRR if there is 0% capital appreciation? What if the property is not sold at the end of its tenure?",
    answer: <p>As for the payouts, there are monthly rental payments made to investors which are credited directly to their bank account. The IRR (Internal Rate of Return) takes into account both the rental returns and any potential capital appreciation. If the property is not sold at the end of its tenure, the monthly rental payments will continue to be made to investors until the property is sold.</p>
  },
  {
    question: "Is my investment liquid?",
    answer: <p>Liquidity for investments on the Brickschain platform may be limited, as the sale of the asset will depend on the availability of willing buyers on the platform. It is recommended to carefully consider your investment horizon and risk tolerance before investing.</p>
  },
  {
    question: "Why commercial real estate?",
    answer: <p>Commercial real estate provides a steady source of income through rent, which makes it a more stable investment compared to other investments. The commercial real estate sector is growing in India, making it an attractive investment opportunity for investors looking for steady returns and capital appreciation.</p>
  },
  {
    question: "What if something goes wrong? Is my investment safe?",
    answer: <p>Your investment is secure as every asset is listed as a separate SPV in the form of a private limited company with independent finances, and your ownership, governance, and compliance records are stored in public databases and government records. In case of any issues with Brickschain, another asset management firm can easily replace us and your investment remains secure.</p>
  },
  {
    question: "How are the investments structured and what exactly will I own legally, as a fraction of the property?",
    answer: <p>The investment is structured in the form of fractional ownership through shares in the SPV (Special Purpose Vehicle) which is a private limited company owning the property. The ownership of these shares represents the investor's fractional ownership of the property and entitles them to receive dividends from rental income generated by the property.</p>
  },
  {
    question: "Are the distributions paid out monthly or annually?",
    answer: <p>Distributions are paid out monthly, as, and when the rentals are received by the Special Purpose Vehicle (SPV) under which the property is registered. This way, we aim to maintain complete financial transparency with our investors.</p>
  },
  {
    question: "Do we get direct ownership of the assets? Can we transfer our ownership to others?",
    answer: <p>Yes, you will get direct ownership of the assets in the form of shares of the private limited company in which the asset is registered, proportional to your investment amount. You can transfer your ownership to the shares to others by selling your shares on the platform to another investor.</p>
  },
  {
    question: "What is the fee model for Brickschain?",
    answer: <p>We charge a management fee of 1% per year plus ______________ at the time of sale of the asset</p>
  },
  {
    question: "Are the distributions transferred monthly or annually?",
    answer: <p>Monthly.</p>
  },
  {
    question: "Who takes care of tenancy and management?",
    answer: <p>The asset manager looks after all aspects of tenancy, asset management, timely transactions & notifications.</p>
  },
  {
    question: "How will I be updated on the progress of my investments?",
    answer: <p>Our investment team regularly updates our investors on the status of the assets, market trends and other relevant information. Communication is key for us, so we make sure to keep our investors informed on a regular basis.</p>
  },
  {
    question: "Will there be a management fee when the property is not tenanted?",
    answer: <p>No, there will be no management fee charged until the investment property is not tenanted.</p>
  },
  {
    question: "What does the management fee paid to Brickschain covers?",
    answer: <p>The management fee covers various expenses including collection of rent, filing of TDS and GST, payment of property taxes, ensuring compliance for the special purpose vehicle (SPV), providing monthly updates to investors, and ensuring insurance coverage for the property.</p>
  },
  {
    question: "Who decides exit timing and pricing?",
    answer: <p>The exit timing and pricing is decided by the investors in consultation with Brickschain. The investors have the right to participate and make decisions in this regard.</p>
  },
  {
    question: "What are the KYC documents needed to sign up with Brickschain?",
    answer: <p>We require the following KYC documents – PAN card and Address Proof (Aadhaar/Driver's license/Passport)</p>
  },
  {
    question: "Can I invest in more than one property and have a portfolio?",
    answer: <p>Yes, you can invest in more than one property and get benefits from a diversified portfolio.</p>
  },
  {
    question: "What is the investment process?",
    answer: <ul className="list-disc list-inside">
      <li>The investment process with Brickschain involves several steps:</li>
      <li>Apply for subscription to the Shares of the Company and pay a 5% token amount to secure your share in the asset.</li>
      <li>Verify your KYC documents which are verified by Brickschain.</li>
      <li>Once the property is fully funded, Brickschain will request the payment of the remaining investment amount.</li>
      <li>Once all investment amounts are received from all investors, the property is registered, the documents are shared with investors, and the shares are transferred to your name and share certificates will be issued to you.</li>
    </ul>
  },
  {
    question: "What happens if I want to withdraw my initial token advance?",
    answer: <p>If you decide to withdraw your token advance before the offer is made, you can do so without penalty. However, if you choose to withdraw after the company has made an offer, a penalty fee of 50% of the token amount will be applied. If the asset does not reach full funding, the token advance will be refunded to the investor's registered bank account.</p>
  },
  {
    question: "What kind of documentation will I need to sign?",
    answer: <p>You will sign an initial application letter and later some documents such as a private placement offer letter (PAS4) with Brickschain. If you are an NRI you will also have to sign a tax declaration form. All these documents will require a digital signature and no physical copies are required. This makes the process faster, transparent, and convenient.</p>
  },
  {
    question: "Do I need to be present for property registration?",
    answer: <p>All the required documentation can be completed online, including the signature process, which eliminates the need for you to be physically present during the property registration process.</p>
  },
  {
    question: "How does Brickschain guarantee that all property titles are in the clear?",
    answer: <p>All property titles are thoroughly vetted and confirmed to be clear of any encumbrances or disputes before the acquisition process begins. Brickschain makes use of a tier 1 law firm for property title reports clearance, which adds another layer of security and reliability to the process.</p>
  },
  {
    question: "Are investors allowed to see rental agreements?",
    answer: <p>This way, investors can have a clear understanding of the terms and conditions agreed upon between the SPV and the tenant, and also monitor the performance of their investment.</p>
  },
  {
    question: "What are the reporting standards and disclosures?",
    answer: <p>Brickschain provides full transparency and regularly updates its investors with real-time reporting through its online dashboard. All-important property-related documents such as legal and rental agreements, tenant details, title reports, due diligence reports, and monthly financials are stored and can be viewed or downloaded from the dashboard.</p>
  },
  {
    question: "Is there any lock-in on my investment?",
    answer: <p>There is no lock-in period for your investment, once the full payment for the property has been made, you can choose to sell your share in the property anytime.</p>
  },
  {
    question: "When is my investment process complete?",
    answer: <p>After all investments are received, the property will be registered, and the shares will be issued to your name. This marks the completion of your investment process.</p>
  },
  {
    question: "How long do you hold these investments for?",
    answer: <p>Brickschain usually holds these investments for a duration of 3-5 years to allow for capital appreciation of the asset. However, investors have the option to exit early through available liquidity options, although this may impact their total returns on the investment.</p>
  },
  {
    question: "How do I exit my investment?",
    answer: <p>Yes, you can exit your investment through resale private sale to another buyer. Brickschain will facilitate the transfer of ownership and onboarding of the new investor on the platform. Brickschain will also enable investors to sell the complete asset in 3-5 years, based on most of the investors' decisions. Once the asset is sold, the sale proceeds will be credited in your registered bank account.</p>
  },
  {
    question: "When I invest in deals on Brickschain, what do I own?",
    answer: <p>Yes, that's correct. When you invest in a property on Brickschain, you will own equity shares of the SPV (Special Purpose Vehicle).</p>
  },
  {
    question: "What are the deductions?",
    answer: <p>The typical deductions are tax deduction at source, property management fee and property tax.</p>
  },
  {
    question: "Does the tenant pay GST? ",
    answer: <p>Yes.</p>
  },
  {
    question: "What are the tax implications on my investments?",
    answer: <p><b>Rents:</b> Rents received from the property are distributed as interest on dividends which are taxable directly in the hands of the investors (post applicable withholding tax which can be claimed by the investor at the end of the year).<br /><br /><b>Capital Gains:</b>The profit from the sale of commercial property is considered capital gains. However, since the property is in the name of the Company, the Company would be liable to discharge the capital gains tax, which will be recovered from the investors in the proportion in which they hold the shares in the Company.</p>
  },
  {
    question: "Is the TDS deposited against my name?",
    answer: <p>Yes, the TDS on your distributions is paid and deposited against your PAN and can be claimed back at the end of the year.</p>
  }

]