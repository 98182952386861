import { combineReducers } from 'redux';
import ToastReducer from "./reducers/ToastReducer";
import UserReducer from './reducers/UserReducer';

const rootReducer = combineReducers({
  toastReducer: ToastReducer,
  userReducer: UserReducer,
});

export default rootReducer;

