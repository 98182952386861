import React, { useState, useEffect } from "react";
import moment from "moment";

const CookiePolicy = () => {
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const prevValue = localStorage.getItem("isCookieAccepted");

    if (prevValue && prevValue !== "") {
      const json = JSON.parse(prevValue);

      if (json.time) {
        const now = moment(new Date());
        const end = moment(json.time);

        const difference = now.diff(end, "days");

        if (difference >= 29) {
          setIsOpen(true);
        } else {
          setIsOpen(false);
        }
      } else {
        setIsOpen(true);
      }
    } else {
      setIsOpen(true);
    }
  }, []);

  const acceptPolicy = () => {
    localStorage.setItem(
      "isCookieAccepted",
      JSON.stringify({
        time: moment(new Date()),
        isAccepted: true,
      })
    );

    setIsOpen(false);
  };

  return (

    <div>
      {
        isOpen ?
          <div className="cookies ">
            <div className="container">
              <div className="flex flex-column flex-md-row cookie_content  py-2 justify-between items-center">
                <div className="left">
                  <p>We use cookies and other tracking technologies to improve your browsing experience on our website, to show you personalized content and targeted ads, to analyze our website traffic, and to understand where our visitors are coming from</p>
                </div>
                <div className="right">
                  <div className="flex">
                    <button onClick={() => acceptPolicy()}>Allow Cookies</button>
                    <button onClick={() => acceptPolicy()}>Close</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          : null
      }
    </div>
    // <Holder style={{ zIndex: 100000 }} open={isOpen}>
    //   <div className="container-fluid">
    //     <div className="row align-items-center justify-content-center">
    //       <div className="col-xs-12 col-md-8 col-lg-9">
    //         <p className="f10 f500 color_blue">
    //           By clicking "Allow All", you agree to the storing of cookies on your device to enhance site navigation, analyze site usage and assist in our marketing efforts. See {' '}
    //           <Link to={"/pages/cookie-policy"}>our Privacy Policy.</Link>.
    //         </p>
    //         {/* <p className="f10 f500 color_blue">
    //           We won't track your information when you visit our site.But in order to comply with your preferences, we'll have to use just one tiny cookie so that you're not asked to make this choice again.
    //             </p> */}
    //       </div>
    //       <div className="justify-content-end d-flex align-items-end col-xs-12 col-md-4 col-lg-3">
    //         <div>
    //           <button className="waves-effect bg_custom_blue bg_custom_blue_hover btn_cookie_policy h_white_blue" onClick={() => acceptPolicy()}>
    //             Allow All
    //           </button>
    //           <button className=" m_l_10 waves-effect bg_custom_blue bg_custom_blue_hover btn_cookie_policy h_white_blue" onClick={() => acceptPolicy()}>
    //             Disable All
    //           </button>
    //         </div>
    //       </div>
    //       {/* <div container spacing={2} alignItems="left">
    //       <div item xs={12} md={6}>
    //         <Button
    //           variant="contained"
    //           color="primary"
    //           fullWidth
    //           onClick={() => acceptPolicy()}
    //         >
    //           Accept & Continue
    //           </Button>
    //       </div>

    //       <div item xs={12} md={6}>
    //         <Button
    //           variant="outlined"
    //           color="primary"
    //           fullWidth
    //           onClick={() => acceptPolicy()}
    //         >
    //           Decline
    //           </Button>
    //       </div>
    //     </div> */}
    //     </div>
    //   </div>
    // </Holder>
  );
};

export default CookiePolicy;

// const Holder = styled.div`
//   width: 100%;
//   position: fixed;
//   bottom: 0;
//   margin-left: auto;
//   margin-right: auto;
//   background-color: ${colors.lightBlueBg};
//   padding: 13px 30px;
//   display: none;
//   box-shadow: 2px 4px 10px 0px rgba(0, 0, 0, 0.1);
//   border-top: 1px solid ${colors.borderColor};

//   ${(props) =>
//     props.open &&
//     `
//     display: block;
//   `}
// `;

// const IMAGE = styled.img`
//   height: 50px;
//   width: auto;
// `;
