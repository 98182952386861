import React from 'react'
import PageHeader from './../../components/PageHeader';

const TermsAndConditions = () => {
  return (
    <>
      <PageHeader location={'TERMS AND CONDITION'} title={'TERMS AND CONDITION'} />
      <section className='section-wrapper'>
        <div className="container">
          <h3 className='heading-3 text-center'>TERMS AND CONDITION</h3>

          <p className='text-center'>These terms and conditions govern your use of the website and any products or
            services offered through the website. By using the Services, you agree to be
            bound by these Terms. If you do not agree to these Terms, please do not use the
            Services.</p>
          <ul className='mt-10'>
            <li className='mb-3'>
              <h6 className='heading-5 '>Eligibility</h6>
              <p>You must be at least 18 years old and have the legal capacity to enter into a
                binding contract to use the Services. If you are using the Services on behalf of a
                company or other organization, you represent that you have the authority to bind
                such entities to these Terms.</p>
            </li>
            <li className='mb-3'>
              <h6 className='heading-5 '>Use of Services</h6>
              <p>You agree to use the Services only for lawful purposes and in compliance with all
                applicable laws, regulations, and guidelines. You agree not to use the Services to:<br />
                <ul className='list-disc list-inside'>
                  <li>
                    Infringe upon the rights of others
                  </li>
                  <li>Transmit any viruses, worms, or other harmful software</li>
                  <li> Impersonate any person or entity or misrepresent your affiliation with any
                    person or entity</li>
                  <li>Engage in any activity that is illegal or violates the rights of others.</li>
                  <li>You agree to comply with all reasonable instructions provided by us with
                    respect to your use of the Services.</li>
                </ul>
              </p>
            </li>
            <li className='mb-3'>
              <h6 className='heading-5 '>Intellectual Property</h6>
              <p>The content and materials provided on the website, including but not limited to
                text, graphics, images, logos, and trademarks, are owned by us or our licensors
                and are protected by copyright, trademark, and other intellectual property laws.
                You may not use or reproduce any content or materials without our prior written
                consent.</p>
            </li>
            <li className='mb-3'>
              <h6 className='heading-5 '>Disclaimer of Warranties</h6>
              <p>The Services are provided on an "as is" and "as available" basis. We make no
                representations or warranties of any kind, express or implied, as to the operation
                of the Services or the information, content, materials, or products included in the
                Services. To the fullest extent permitted by applicable law, we disclaim all warranties, express or implied, including but not limited to implied warranties of
                merchantability and fitness for a particular purpose.</p>
            </li>
            <li className='mb-3'>
              <h6 className='heading-5 '>Limitation of Liability</h6>
              <p>In no event shall we be liable for any direct, indirect, incidental, special, or
                consequential damages arising out of or in connection with the Services or these
                Terms, whether based on warranty, contract, tort, or any other legal theory. Our
                liability for any claim shall not exceed the amount paid by you for the Services.</p>
            </li>
            <li className='mb-3'>
              <h6 className='heading-5 '>Indemnification</h6>
              <p>You agree to indemnify and hold us harmless from and against any and all
                claims, damages, losses, liabilities, and expenses (including reasonable
                attorneys' fees) arising out of or in connection with your use of the Services, your
                breach of these Terms, or your violation of any law or the rights of a third party.</p>
            </li>
            <li className='mb-3'>
              <h6 className='heading-5 '>Governing Law and Dispute Resolution</h6>
              <p>These Terms and any dispute arising out of or in connection with these Terms or
                the Services shall be governed by and construed in accordance with the laws of
                India. Any dispute or claim arising out of or in connection with these Terms or the
                Services shall be finally settled by arbitration in accordance with the provisions of
                the Indian Arbitration and Conciliation Act, 1996.</p>
            </li>
            <li className='mb-3'>
              <h6 className='heading-5 '>Changes to Terms</h6>
              <p>We may modify these Terms at any time, and such modifications shall be
                effective immediately upon posting on the website. Your continued use of the
                Services after the posting of any modified Terms constitutes your agreement to
                such modified Terms.</p>
            </li>
            <li className='mb-3'>
              <h6 className='heading-5 '>Termination</h6>
              <p>We may terminate your use of the Services at any time and for any reason
                without notice or liability.</p>
            </li>
            <li className='mb-3'>
              <h6 className='heading-5 '>Entire Agreement</h6>
              <p>These Terms constitute the entire agreement between you and us with respect to
                the Services and supersede all prior or contemporaneous communications and
                proposals, whether oral</p>
            </li>
          </ul>
        </div>
      </section>
    </>
  )
}

export default TermsAndConditions