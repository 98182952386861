import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { icons } from '@utils/icons';
import { postReq } from '@utils/ApiHandler';
import { useDispatch } from 'react-redux';
import { showToast } from '@redux/action';
import Loader from '@components/Loader';
import { useHistory } from 'react-router-dom'

const Footer = () => {
  //state
  const [isLoading, setIsLoading] = useState(false)
  const [email, setEmail] = useState(null);
  const history = useHistory();

  const whitePaper = `/images/whitepaper.pdf`;

  //hooks
  const dispatch = useDispatch();

  //function
  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  //Api call
  const handleSubmit = (e) => {
    e.preventDefault()
    setIsLoading(true);
    postReq('/subscribe-mail', { "email": email }).then((res) => {
      if (res.status) {
        if (res.data.data) {
          setEmail('')
          dispatch(showToast({ type: 'success', message: 'Email subscribe successfully.' }));
          setIsLoading(false);
        }
        else {
          setEmail('')
          dispatch(showToast({ type: 'error', message: res.data.error }))
          setIsLoading(false)
        }
      }
      else {
        setEmail('')
        dispatch(showToast({ type: 'error', message: res.data.error }))
        setIsLoading(false);
      }
    }).catch((e) => {
      console.error(e)
    })
  }
  return (
    <>
      <Loader isLoading={isLoading} />
      <footer className=' bg-primary-black  relative overflow-hidden'>
        <div className="absolute top-0 left-0 bottom-0"><img src="/images/site-footer-shape.png" alt="" /></div>
        <div className="container relative  pt-[50px] lg:pt-[80px] z-[1] text-white">
          <div className="grid grid-cols-4 lg:grid-cols-8 gap-6 lg:gap-[120px] pb-7   ">
            <div className="tablet:col-span-1 col-span-4 mb-1 lg:mb-0">
              <div className="w-[150px] h-auto">
                <img style={{ 'cursor': 'pointer' }} onClick={() => history.push('/')} src="/images/logo/ira-logo1.png" className='w-full h-full object-cover' alt="" />
              </div>
            </div>
            <div className="col-span-3">
              <div className=" h-auto">
                <h5 className='heading-4 mb-2 md:mb-0'>Explore</h5>
                <div className="md:pt-[20px] flex lg:justify-between gap-6 tablet:gap-12 lg:gap-[68px]">
                  <ul className='flex flex-col whitespace-nowrap'>
                    <NavLink to='/about' className='relative group footer_link'>About
                      <div className='bg-primary-main absolute left-0 bottom-[-2px] group-hover:w-full h-[2px]'></div>
                    </NavLink>
                    <NavLink to='/contact' className='relative group footer_link'>Contact
                      <div className='bg-primary-main absolute left-0 bottom-[-2px] group-hover:w-full h-[2px]'></div>
                    </NavLink>
                    <NavLink to='/commercial-properties' className='relative group footer_link'>Commercial Opportunities<div className='bg-primary-main absolute left-0 bottom-[-2px] group-hover:w-full h-[2px]'></div></NavLink>
                  </ul>
                  <ul className='flex flex-col whitespace-nowrap'>
                    <a to='/' href={whitePaper} target='_blank' rel="noreferrer" className='relative group footer_link'>WhitePaper <div className='bg-primary-main absolute left-0 bottom-[-2px] group-hover:w-full h-[2px]'></div></a>
                    <NavLink to='/privacy-policy' className='relative group footer_link'>Privacy Policy <div className='bg-primary-main absolute left-0 bottom-[-2px] group-hover:w-full h-[2px]'></div></NavLink>
                    <NavLink to='/terms-condition' className='relative group footer_link'>Terms & Conditions <div className='bg-primary-main absolute left-0 bottom-[-2px] group-hover:w-full h-[2px]'></div></NavLink>
                    {/* <NavLink to='/faq' className='relative group footer_link'>FAQ<div className='bg-primary-main absolute left-0 bottom-[-2px] group-hover:w-full h-[2px]'></div></NavLink> */}
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-span-4 tablet:col-span-3">
              <div className="">
                <h5 className='heading-4 mb-2 md:mb-0'>Contact</h5>
                <div className="md:pt-[20px]">
                  <p className='text-primary-gray2 md:leading-[30px]'><a href="https://www.google.com/maps/place/STPI+CoE+-+Motion/@18.6231675,73.8355731,17z/data=!3m1!4b1!4m6!3m5!1s0x3bc2b9873aaded19:0xad371d460b1af5b0!8m2!3d18.6231675!4d73.8377618!16s%2Fg%2F11r7qc31vt" target="_blank" rel="noopener noreferrer">STPI, Gala no. 5, Electronics Sadan III, MIDC Indl Estate, Bhosari, Pune</a></p>
                  <div className="pt-3 md:pt-[26px]">
                    <div className="flex items-center gap-[10px]">
                      <div className="w-[12px] h-[12px] flex-shrink-0">
                        <img className='w-full' src="/images/mssg.png" alt="" />
                      </div>
                      <div className="heading-7">support@brickschain.tech</div>
                    </div>
                    {/* <div className="flex items-center gap-[10px] mt-[10px]">
                      <div className="w-[12px] h-[12px] flex-shrink-0">
                        <img src="/images/call.png" alt="" />
                      </div>
                      <div className="heading-7">+91 (8800) - 8960</div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>

          </div>
          <p className='leading-6 text-sm text-primary-gray2 pb-7 text-justify lg:text-left'>Brickschain.tech is a website operated by Brickschain Private Limited (CIN: U45202PN2022PTC211358) and by accessing the website and any pages thereof, you agree to be bound by its terms of service and privacy policy. Brickchain does not prima facie endorse or promote any of the opportunities that appear on this website nor make any recommendations regarding same to any Investor. Prospective investors are not to construe anything on the website as investment, business, legal or tax advice and the content contained herein does not constitute an offer by Brickchain to sell securities. Any information made available from our website or links to websites, do not represent a solicitation of an offer to buy or sell any property. It also does not constitute an offer to provide investment advice, service or assistance on particular investment or transaction. Direct and indirect purchase of real property involves significant risk and investments may lose value and are not insured by any Governmental Agency nor are they guaranteed by Brickchain. It is the responsibility of the recipient to verify the integrity and authenticity of the information made available. Any historical returns, expected returns, or probability projections may not reflect actual future performance. Investors must be able to afford to bear losses on investment made.</p>
          <div className="flex tablet:flex-row flex-col gap-4 items-center justify-between py-[16px] border-t border-primary-gray">
            <div className="">© All Copyright {new Date().getFullYear()} by Brickschain.tech</div>
            <div className="flex items-center gap-2">
              <a className='w-10 h-10 center rounded-full bg-primary-black2 hover:text-primary-main transition-all hover:scale-110' href="https://www.facebook.com/brickschainproperty" target="_blank" rel="noopener noreferrer">{icons.facebook}</a>
              <a className='w-10 h-10 center rounded-full bg-primary-black2 hover:text-primary-main transition-all hover:scale-110' href="https://twitter.com/brickschain" target="_blank" rel="noopener noreferrer">{icons.twitter}</a>
              <a className='w-10 h-10 center rounded-full bg-primary-black2 hover:text-primary-main transition-all hover:scale-110' href="https://www.instagram.com/brickschain/?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noopener noreferrer">{icons.instagram}</a>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer