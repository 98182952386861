import ApartmentCard from '@components/cards/ApartmentCard'
import PageHeader from '@components/PageHeader'
import { getReq } from '@utils/ApiHandler';
import React, { useEffect, useState } from 'react'

const Apartments = () => {
  const [apartment, setApartment] = useState(null);

  useEffect(() => {
    getReq('/get-properties').then((res) => {
      if (res.status) {
        let image = res.data[0].property_images.split(',')
        setApartment(res.data)
      }
    })
  }, [])
  return (
    <div>
      <PageHeader location={'apartments'} title={'Commercial Properties'} />
      <section className="section-wrapper">
        <div className="container flex justify-center gap-7 flex-wrap">
          {apartment?.map((item, index) => (
            <ApartmentCard item={item} key={index} />
          ))}

        </div>
      </section>

    </div>
  )
}

export default Apartments