import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
// import PhoneInput from 'react-phone-number-input'
import { postApiReq } from '@utils/ApiHandler';
import { UpdateProfileValidation, EmailValidation, MobileValidation, AadharValidation, PancardValidation } from '@utils/Auth';
import { PinInput, PinInputField, HStack, Tooltip } from '@chakra-ui/react'
import Loader from '@components/Loader';
import { showToast, setUserDetails } from '@redux/action';
import { isYupError, parseYupError } from '@utils/Yup';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { icons } from '@utils/icons';


export default function Profile({ getUserDetailsApi }) {
  const { username, email, phone, isEmailVerified, aadharCard, pancard, isPhoneVerified, isKYC, bankVerificationStatus } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();
  const history = useHistory();
  const { pathname } = useLocation();
  const [errors, setErrors] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [mobileOtpVisible, setMobileOtpVisible] = useState(false);
  const [aadharOtpVisible, setAadharOtpVisible] = useState(false);
  const [doNotClick, setDoNotClick] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [otp, setOtp] = useState({
    mobileOtp: null,
    aadharOtp: null
  })
  const [userData, setUserData] = useState({
    fullName: '',
    email: '',
    phone: null,
    aadharCard: null,
    pancard: null
  })

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value
    })
    setErrors({
      ...errors,
      [name]: ''
    })
  }

  const setFormData = () => {
    setUserData({ fullName: username, email, phone, aadharCard, pancard })
  }

  const updateProfile = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      await UpdateProfileValidation.validate(userData, {
        abortEarly: false,
      })
      setIsLoading(true);
      postApiReq('/user/update-user-details', userData).then((res) => {
        if (res.status) {
          if (res.data.status === 1) {
            getUserDetailsApi();
            setIsLoading(false);
            dispatch(showToast({ type: 'success', message: 'Details Updated Successfully!' }));
            getUserDetailsApi();
          } else {
            setIsLoading(false);
            dispatch(showToast({ type: 'error', message: res.data.error }))
          }
        }
        else {
          setIsLoading(false);
          dispatch(showToast({ type: 'error', message: "An error Occured, Please try again later." }))
        }
      })

    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.error('error');
      }
    }
  }

  const handleVerifyMail = async (e) => {
    e.preventDefault();
    const data = { email: userData.email };
    try {
      setErrors({});
      await EmailValidation.validate(data, {
        abortEarly: false,
      })
      setIsLoading(true);
      postApiReq('/user/resend-mail-verification', data).then((res) => {
        if (res.status) {
          if (res.data.status === 1) {
            setIsLoading(false);
            Cookies.set('pathname', JSON.stringify({ pathname: pathname }))
            dispatch(showToast({ type: 'success', message: "Verification Mail has been sent!" }));
            getUserDetailsApi();
          } else {
            setIsLoading(false);
            dispatch(showToast({ type: 'error', message: "An error Occured, Please try again later." }))
          }
        }
        else {
          setIsLoading(false);
          dispatch(showToast({ type: 'error', message: "An error Occured, Please try again later." }))
        }
      })
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.error('error');
      }
    }
  }

  const handleOTPverification = async (e) => {
    e.preventDefault();
    if (!doNotClick) {
      const data = { phone: userData.phone };
      try {
        setErrors({});
        await MobileValidation.validate(data, {
          abortEarly: false,
        })
        setIsLoading(true);
        postApiReq('/user/send-otp', data).then((res) => {
          setIsLoading(false);
          if (!res.status) {
            dispatch(showToast({ type: 'error', message: 'Something Went Wrong' }));
          } else if (res.data.status === 0) {
            dispatch(showToast({ type: 'error', message: res.data.error }));
          } else {
            dispatch(showToast({ type: 'success', message: "OTP Sent Successfully" }));
            setMobileOtpVisible(true);
            setDoNotClick(true);

          }
        })
      } catch (error) {
        if (isYupError(error)) {
          setErrors(parseYupError(error));
        } else {
          console.error('error');
        }
      }
    } else {
      dispatch(showToast({ type: 'error', message: 'OTP Already Sent' }));
    }
  }

  const verifyMobileOtp = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const data = { phone: userData.phone, otp: otp.mobileOtp };
    postApiReq('/user/verify-otp', data).then((res) => {
      setIsLoading(false);
      if (!res.status) {
        dispatch(showToast({ type: 'error', message: 'Something Went Wrong' }));
      } else if (res.data.status === 0) {
        dispatch(showToast({ type: 'error', message: res.data.error }));
      } else {
        setMobileOtpVisible(false);
        dispatch(setUserDetails({ isPhoneVerified: true }));
        dispatch(showToast({ type: 'success', message: "Mobile Verification Successful" }));
        getUserDetailsApi();

      }
    })
  }

  const handlePanCardVerification = async (e) => {
    e.preventDefault();
    const data = { pancard: userData.pancard }

    try {
      setErrors({});
      await PancardValidation.validate(data, {
        abortEarly: false,
      })
      setIsLoading(true);
      postApiReq('/user/verify-pan-card', data).then((res) => {
        setIsLoading(false);
        if (!res.status) {
          dispatch(showToast({ type: 'error', message: 'Something Went Wrong' }))
        } else if (res.data.status === 0) {
          dispatch(showToast({ type: 'error', message: res.data.error }));
        }
        else {
          dispatch(setUserDetails({ pancard: userData.pancard }));
          dispatch(showToast({ type: 'success', message: "Pancard Verified Successfully" }));
          getUserDetailsApi();
        }
      })
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.error('error');
      }
    }

  }

  const handelGenerateAadharOTP = async (e) => {
    e.preventDefault();
    const data = { aadharCard: Number(userData.aadharCard) };

    try {
      setErrors({});
      await AadharValidation.validate(data, {
        abortEarly: false,
      })
      setIsLoading(true);
      postApiReq('/user/generate-aadhar-otp', data).then((res) => {
        setIsLoading(false);
        if (!res.status) {
          dispatch(showToast({ type: 'error', message: 'Something Went Wrong' }))
        } else if (res.data.status === 0) {
          dispatch(showToast({ type: 'error', message: res.data.error }));
        }
        else {
          setAadharOtpVisible(true);
          setClientId(res.data.data.data.client_id);
          dispatch(showToast({ type: 'success', message: "OTP sent Succesfully" }));
        }
      })
    }
    catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.error('error');
      }
    }
  }

  const verifyAadharOTP = async (e) => {
    e.preventDefault();
    const data = { aadharCard: userData.aadharCard, otp: otp.aadharOtp, clientId };

    setIsLoading(true);
    postApiReq('/user/verify-aadhar', data).then((res) => {
      setIsLoading(false);
      if (!res.status) {
        dispatch(showToast({ type: 'error', message: 'Something Went Wrong' }))
      } else if (res.data.status === 0) {
        setAadharOtpVisible(false);
        setOtp({ ...otp, aadharOtp: null });
        dispatch(showToast({ type: 'error', message: res.data.error }));
      }
      else {
        setAadharOtpVisible(false);
        // dispatch(setUserDetails({ aadharCard: userData.aadharCard }));
        window.location.reload();
        dispatch(showToast({ type: 'success', message: "Aadhar Verified Successfully" }));
        getUserDetailsApi();
      }
    })
  }


  useEffect(() => {
    setFormData();
  }, [username])


  return (
    <>
      <Loader isLoading={isLoading} />
      <div className='py-12'>
        <h3 className='heading-2 text-black text-center relative mb-6'>
          Update Profile
          <div className="absolute bottom-0 right-0 left-0 mx-auto w-28 h-[2px] bg-primary-main  rounded-md "></div>
        </h3>
        <div className="container">
          {isKYC &&
            <div className='flex justify-end items-center gap-2'>
              <button onClick={() => history.push('/add-bank-details')} className='btn btn-primary'>{(bankVerificationStatus === null) ? `Add Bank Details` : 'View Bank Details'} </button>
              <div className=''>
                <Tooltip label='Please add Bank Details then,Our team will verify it.'>
                  <span className='text-primary-main text-md'>{icons.Question}</span>
                </Tooltip>
              </div>
            </div>
          }

          <form action="" className='max-w-[400px] w-full mx-auto  flex flex-col gap-4'>
            <div className='flex flex-col gap-2'>
              <label htmlFor="">Full Name</label>
              <input onChange={handleChange} name="fullName" type="text" placeholder='Full Name' value={userData.fullName} />
            </div>
            {errors && <span className='text-red-500'>{errors.fullName}</span>}
            <div className='flex flex-col gap-2'>
              <label htmlFor="">Email</label>
              <div className='flex items-center gap-3 relative'>
                <input onChange={handleChange} name="email" type="text" placeholder='Email' value={userData.email} />
                {(!isEmailVerified) ?
                  <button className='px-3 h-[30px] bg-primary-main text-white' style={{ 'fontSize': '15px' }} onClick={handleVerifyMail}>Verify</button> : <span className='text-xl text-primary-main absolute right-2 top-4'>{icons.tick}</span>
                }
              </div>
            </div>
            {errors && <span className='text-red-500'>{errors.email}</span>}
            <div className='flex flex-col gap-2'>
              <label htmlFor="">Phone</label>
              <div className='flex items-center gap-3 relative'>
                <input onChange={handleChange} name="phone" type="text" placeholder='Phone' value={userData.phone} />
                {(!isPhoneVerified) ?
                  <button className='px-3 h-[30px] bg-primary-main text-white' style={{ 'fontSize': '15px' }} onClick={handleOTPverification}>Verify</button> : <span className='text-xl text-primary-main absolute right-2 top-4'>{icons.tick}</span>
                }
              </div>
            </div>
            {errors && <span className='text-red-500'>{errors.phone}</span>}
            {(mobileOtpVisible) &&
              <div className='flex items-center gap-20' >
                {/* onChange={(e) => setOtp({ ...otp, mobileOtp: e.target.value })} */}
                <div className='flex gap-3 '>
                  <HStack>
                    <PinInput type='number' variant="flushed" onChange={(e) => setOtp({ ...otp, mobileOtp: e })} value={otp.mobileOtp} manageFocus="true">
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>

                </div>
                <button className='px-3 h-[30px] bg-primary-main text-white' style={{ 'fontSize': '15px' }} onClick={verifyMobileOtp}>Submit</button>
              </div>
            }

            <div className='flex flex-col gap-2'>
              <label htmlFor="">Pancard</label>
              <div className='flex items-center gap-3 relative'>
                <input readOnly={(pancard === null || pancard === "") ? false : true} onChange={handleChange} name="pancard" type="text" placeholder='Pancard' value={userData?.pancard?.toUpperCase()} />

                {(pancard === null || pancard === "") ?
                  <button className='px-3 h-[30px] bg-primary-main text-white' style={{ 'fontSize': '15px' }} onClick={handlePanCardVerification}>Verify</button> : <span className='text-xl text-primary-main absolute right-2 top-4'>{icons.tick}</span>
                }
              </div>
            </div>
            {errors && <span className='text-red-500'>{errors.pancard}</span>}
            <div className='flex flex-col gap-2'>
              <label htmlFor="">Aadhar Card</label>
              <div className='flex items-center gap-3 relative'>
                <input readOnly={(aadharCard === null || aadharCard === "") ? false : true} onChange={handleChange} name="aadharCard" type="text" placeholder='Aadhar Card' value={(!aadharCard) ? userData.aadharCard : JSON.parse(aadharCard)['address']['house']} />
                {(aadharCard === null || aadharCard === "") ?
                  <button className='px-3 h-[30px] bg-primary-main text-white' style={{ 'fontSize': '15px' }} onClick={handelGenerateAadharOTP}>Verify</button> : <span className='text-xl text-primary-main absolute right-2 top-4'>{icons.tick}</span>
                }
              </div>
            </div>
            {errors && <span className='text-red-500'>{errors.aadharCard}</span>}
            {(aadharOtpVisible) &&
              <div className='flex items-center gap-20' >
                <HStack>
                  <PinInput type='number' variant="flushed" onChange={(e) => setOtp({ ...otp, aadharOtp: e })} value={otp.aadharOtp} >
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
                <button className='px-3 h-[30px] bg-primary-main text-white' style={{ 'fontSize': '15px' }} onClick={verifyAadharOTP}>Submit</button>
              </div>
            }
            <button type='submit' onClick={updateProfile} className='btn2 btn-primary text-md'>Update Profile</button>
          </form>
        </div>
      </div>
    </>
  )
}
