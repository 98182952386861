import React, { useState } from 'react'
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import { FiX } from 'react-icons/fi';
import { icons } from '@utils/icons';

const PaymentOption = ({ setBankStatement, isOpen, onOpen, setPaymentOption }) => {
  return (
    <>
      <Modal
        isCentered
        size={'md'}
        isOpen={true}
        onClose={false}
        motionPreset='slideInBottom'
      >
        <ModalOverlay className='!backdrop-blur-sm' />
        <ModalContent className='mx-2'>
          <ModalBody className='!p-0 rounded-md overflow-hidden'>

            <div className="form flex overflow-hidden  max-w-[500px] w-full text-primary-gray rounded border-primary-border ">
              <div className="relative w-full" style={{ 'display': 'flex', 'flexDirection': 'column' }}>
                <div className=''>
                  <FiX style={{ 'float': 'right', 'margin': '10px 10px 0 0', 'cursor': 'pointer', 'fontSize': '20px' }} onClick={() => setPaymentOption(false)} />
                </div>
                <div className='h-fit w-[100%] flex-shrink-0 p-10 bg-white' >
                  <p className='font-semibold pb-4 text-[17px]'>Choose Options to Pay for Fractions</p>
                  <div className='flex justify-between gap-x-4'>
                    <button className="btn-primary h-[52px] lg:max-w-[200px] w-full mt-[10px] center text-[16px]" onClick={() => { onOpen(); setPaymentOption(false) }} type="submit">Bank Transfer</button>
                    <button className="btn-primary h-[52px] lg:max-w-[200px] w-full mt-[10px] center text-[16px] cursor-not-allowed" type="submit">UPI</button>
                  </div>
                </div>
              </div>
            </div>

          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  )
}

export default PaymentOption;