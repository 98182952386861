import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/scss";
import "swiper/scss/pagination";
import "swiper/scss/navigation";

import {
  Pagination,
  Navigation,

} from "swiper";
import { useState } from "react";
import { icons } from "@utils/icons";

const HomeSlider = () => {
  const [slide, setSlide] = useState(0);
  const slides = [];

  return (
    <div className="relative">
      {/* <div className="flex flex-col gap-4 ay-center right-[20%] z-[50]">
        <button className="home-header-swiper-button-prev w-[50px] xxl:w-[60px] h-[50px] xxl:h-[60px] opacity-70 hover:opacity-100 duration-200 text-white border-[2px] center text-lg border-white rounded-full">{icons.bsleft}</button>
        <button className="home-header-swiper-button-next w-[50px] xxl:w-[60px] h-[50px] xxl:h-[60px] opacity-70 hover:opacity-100 duration-200 text-white border-[2px] center text-lg border-white rounded-full">{icons.bsright}</button>
      </div> */}
      {/* <Swiper
        slidesPerView={1}
        spaceBetween={16}
        loop={true}
        loopFillGroupWithBlank={true}
        grabCursor={false}
        centeredSlides={true}
        navigation={{
          nextEl: ".home-header-swiper-button-next",
          prevEl: ".home-header-swiper-button-prev",
        }}
        modules={[Pagination, Navigation]}
        className=""
        onSlideChange={(swiper) => {
          setSlide(swiper.activeIndex);
        }}
      > */}

      {/* <SwiperSlide> */}
      <div className="min-h-[640px] xxl:min-h-[740px]  relative container flex lg:flex-row flex-col lg:items-center lg:justify-start justify-center">
        <div className="max-w-[800px]">
          <h1 className="text-white lg:text-[60px] text-[30px] leading-[55px] lg:leading-[80px] font-bold w-full">Looking for passive income by investing in variety of physical assets?
            <br />
            <br />
            Well, you're at the right door!</h1>
          {/* <div className="lg:max-w-[370px] max-w-[350px] pt-32 lg:pt-24">
            <div className="w-full bg-primary-black2 lg:p-10 p-7 flex items-center gap-12">
              <div>
                <img src="/images/home/ap-header.svg" alt="building" />
              </div>
              <div>
                <h6 className="text-white font-teko tracking-c font-medium heading-3 ">37400 </h6>
                <p className="font-teko text-primary-gray uppercase">Total sqft Apartments</p>
              </div>

            </div>
          </div> */}
        </div>

      </div>

      {/* </SwiperSlide> */}

      {/* </Swiper> */}
    </div>
  );
};

export default HomeSlider;
