/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { icons } from '@utils/icons';

const OurAgentCard = ({name, img, linkdin}) => {
    return (
        <>
            <div className="max-w-[322px] group/border transition-all tablet:max-w-[340px]  lg:w-[calc(100%/3-60px)] tablet:w-[calc(100%/2-60px)] ">
                <div className=" relative h-[350px]  bg-primary-black ">
                    <img className='absolute-img cursor-pointer' src={img} alt="" />
                    <div className="w-full absolute -bottom-[20px] -right-[20px] h-[350px] border-r border-b border-primary-border group-hover/border:transition-all group-hover/border:border-primary-main"></div>
                    {/* <div className="absolute bottom-0 right-0">
                        <div className=" dropdown hover">
                            <a className='text-white w-[50px] h-[50px] hover:bg-primary-background2 hover:text-primary-black2 bg-primary-main' href="#">{icons.share}</a>
                            <ul>
                                <li><a className='text-white w-[50px] h-[50px] hover:bg-primary-background2 hover:text-primary-black2 bg-primary-main' href="#">{icons.facebook}</a></li>
                                <li><a className='text-white w-[50px] h-[50px] hover:bg-primary-background2 hover:text-primary-black2 bg-primary-main' href="#">{icons.twitter}</a></li>
                                <li><a className='text-white w-[50px] h-[50px] hover:bg-primary-background2 hover:text-primary-black2 bg-primary-main' href="#">{icons.instagram}</a></li>
                            </ul>
                        </div>
                    </div> */}
                </div>
                <div className="mt-[32px]">
                    <div className="">
                        <h4 className='text-[24px] font-teko font-normal leading-[24px]'>{name}</h4>
                        {/* <p className='text-primary-gray'>Architecture</p> */}
                    </div>
                </div>
            </div>
        </>
    )
}

export default OurAgentCard