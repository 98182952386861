import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { addPaymentRequest } from "@utils/Auth";
import ENV from "@utils/env";
import { postApiReq } from "@utils/ApiHandler";
import { showToast } from "@redux/action";
import { isYupError, parseYupError } from "@utils/Yup";
import Loader from "@components/Loader";

const BankStatement = ({
  propertyId,
  totalAmount,
  fractions,
  setPaymentOption,
  setBankStatement,
  onClose,
  setEligibleForPayment,
  setAmount,
  setFractionCount,
  checkFractionRequest
}) => {
  const dispatch = useDispatch();

  const [bankDetails, setBankDetails] = useState({
    senderName: "",
    senderBankAccount: "",
    senderBankName: "",
    transactionDescription: "",
  });

  const [errors, setErrors] = useState(null);
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [scrollBehavior, setScrollBehavior] = React.useState("inside");

  const Swal = require("sweetalert2");

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setBankDetails({
      ...bankDetails,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "",
    });
  };

  const submitPaymentBankDetails = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      await addPaymentRequest.validate(bankDetails, {
        abortEarly: false,
      });
      setIsLoading(true);

      const data = { ...bankDetails, propertyId, totalAmount, fractions };
      postApiReq("/user/add-fraction-payment-request", data)
        .then((res) => {
          setIsLoading(false);
          console.log(res);
          if (res.status) {
            if (res.data.status === 0) {
              dispatch(showToast({ type: "error", message: "Something Went Wrong" }));
            } else {
              Swal.fire({
                title: "Purchase Fraction Request Created Succesfully ",
                text: "Please Wait While Our team Approve your Payment",
                icon: "success",
                confirmButtonText: "Close",
              });
              checkFractionRequest();
              // dispatch(showToast({ type: 'success', message: 'Requested Created Succesfully' }));
              setPaymentOption(false);
              setBankStatement(false);
              onClose();
            }
          } else {
            dispatch(showToast({ type: "error", message: res.error }));
            setIsLoading(false);
          }
        })
        .catch((e) => {
          console.error("error", e);
        });
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.error("error");
      }
    }
  };
  return (
    <>
      <Loader isLoading={isLoading} />
      <Modal
        isCentered
        size={"xl"}
        isOpen={true}
        onClose={false}
        motionPreset="slideInBottom"
        scrollBehavior={scrollBehavior}
      >
        <ModalOverlay className="!backdrop-blur-sm" />
        <ModalContent className="!mx-4 !max-w-[350px] tablet:!max-w-[500px]">
          <ModalBody className="!p-4 rounded-md overflow-hidden">
            <div className="form overflow-hidden w-full text-primary-gray rounded border-primary-border  overflow-x-hidden">
              <div
                className={`flex w-[200%] duration-200 ${step === 2 && `translate-x-[-50%]`
                  }`}
              >
                <div className="w-[100%]">
                  <div className=" bg-white">
                    <h3 className="font-semibold pb-4  text-center heading-4">
                      Pay Amount on Below Bank Details
                    </h3>
                  </div>
                  <div className=" flex flex-col gap-y-4 border-2 p-5 mb-5">
                    <div className="flex gap-2 item-center">
                      <label
                        htmlFor="name"
                        className=" w-[110px] "
                      >
                        Holder Name
                      </label>
                      <input
                        className=" flex-1"
                        type="text"
                        name="name"
                        id=""
                        placeholder={ENV.ACCOUNT_HOLDER_NAME}
                        readOnly
                      />
                    </div>
                    <div className="flex gap-2 item-center">
                      <label
                        htmlFor="name"
                        className="flex-shrink-0 w-[110px] "
                      >
                        Bank Name
                      </label>
                      <input
                        className="flex-1"
                        type="text"
                        name="name"
                        id=""
                        placeholder={ENV.BANK_NAME}
                        readOnly
                      />
                    </div>
                    <div className="flex gap-2 item-center">
                      <label
                        htmlFor="name"
                        className="flex-shrink-0 w-[110px] "
                      >
                        Branch Name
                      </label>
                      <input
                        className="flex-1"
                        type="text"
                        name="name"
                        id=""
                        placeholder={ENV.BRANCH_NAME}
                        readOnly
                      />
                    </div>
                    <div className="flex gap-2 item-center">
                      <label
                        htmlFor="name"
                        className="flex-shrink-0 w-[110px] "
                      >
                        Acount No.
                      </label>
                      <input
                        className=" flex-1"
                        type="text"
                        name="name"
                        id=""
                        placeholder={ENV.ACCOUNT_NO}
                        readOnly
                      />
                    </div>
                    <div className="flex gap-2 item-center">
                      <label
                        htmlFor="name"
                        className="flex-shrink-0 w-[110px] "
                      >
                        Bank IFSC
                      </label>
                      <input
                        className="flex-1"
                        type="text"
                        name="name"
                        id=""
                        placeholder={ENV.IFSC_CODE}
                        readOnly
                      />
                    </div>
                    <div className="flex gap-2 item-center">
                      <label
                        htmlFor="name"
                        className="flex-shrink-0 w-[110px] "
                      >
                        Total Amount
                      </label>
                      <input
                        className="flex-1"
                        type="text"
                        name="name"
                        id=""
                        placeholder={totalAmount}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="w-[100%]">
                  <h3 className="font-semibold pb-4  text-center heading-4">
                    Enter Sender Details
                  </h3>

                  <div className="flex flex-col gap-y-4 border-2 p-5 mb-5">
                    <div className="flex gap-2 item-center">
                      <label
                        htmlFor="name"
                        className="flex-shrink-0 w-[110px] "
                      >
                        Sender Name
                      </label>
                      <input
                        className="flex-1 "
                        type="text"
                        name="senderName"
                        id=""
                        onChange={handleChange}
                        placeholder="Sender Name"
                      />
                    </div>
                    {errors && (
                      <Box sx={{ color: "red" }}>{errors.senderName}</Box>
                    )}

                    <div className="flex gap-2 item-center">
                      <label
                        htmlFor="name"
                        className="flex-shrink-0 w-[110px] "
                      >
                        Sender Account Number
                      </label>
                      <input
                        className="flex-1 "
                        type="text"
                        name="senderBankAccount"
                        id=""
                        onChange={handleChange}
                        placeholder="Sender Account Number"
                      />
                    </div>
                    {errors && (
                      <Box sx={{ color: "red" }}>
                        {errors.senderBankAccount}
                      </Box>
                    )}

                    <div className="flex gap-2 item-center">
                      <label
                        htmlFor="name"
                        className="flex-shrink-0 w-[110px] "
                      >
                        Bank Name
                      </label>
                      <input
                        className="flex-1  "
                        type="text"
                        name="senderBankName"
                        id=""
                        onChange={handleChange}
                        placeholder="Bank Name"
                      />
                    </div>
                    {errors && (
                      <Box sx={{ color: "red" }}>{errors.senderBankName}</Box>
                    )}

                    <div className="flex gap-2 item-center">
                      <label
                        htmlFor="name"
                        className="flex-shrink-0 w-[110px] "
                      >
                        Transaction Description
                      </label>
                      <input
                        className="flex-1 "
                        type="text"
                        name="transactionDescription"
                        id=""
                        onChange={handleChange}
                        placeholder="Transaction Description"
                      />
                    </div>
                    {errors && (
                      <Box sx={{ color: "red" }}>
                        {errors.transactionDescription}
                      </Box>
                    )}
                  </div>
                </div>
              </div>
              <div className="center justify-end gap-5 mt-2">
                <button onClick={() => {
                  setBankStatement(false);
                  setEligibleForPayment(false);
                  setAmount(0);
                  setFractionCount(0);
                }}
                  className="btn btn-primary">Cancel</button>
                {step === 1 && <button onClick={() => setStep(2)} className="btn btn-primary">Next</button>}
                {step === 2 && <button onClick={() => setStep(1)} className="btn btn-primary">Prev</button>}
                {step === 2 && <button onClick={(e) => submitPaymentBankDetails(e)} className="btn btn-primary">Submit</button>}
              </div>
              {/* <div className="center gap-5 mt-6">
                <button className="btn btn-primary">Cancel</button>
                <button
                  className="btn btn-primary"
                  onClick={(e) => submitPaymentBankDetails(e)}
                >
                  Submit
                </button>
              </div> */}
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BankStatement;
