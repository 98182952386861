import React from "react";
import { IoMdMail } from 'react-icons/io'
import { BsFillShareFill, BsArrowLeft, BsArrowRight, BsPlayFill } from 'react-icons/bs';
import { FiArrowRight, FiArrowLeft, FiChevronRight, FiChevronDown, FiX } from 'react-icons/fi';
import { BiWalletAlt, BiPencil, BiTimeFive, BiUserCircle } from "react-icons/bi";
import { FaFacebookF, FaLinkedinIn, FaCircleNotch, FaSpinner, FaPinterestP, FaComment } from 'react-icons/fa';
import { BsInfoCircle, BsArrowRightShort, BsFillPlayFill, BsTelephone, BsFillTelephoneFill, BsCheckCircleFill, BsQuestionCircleFill } from 'react-icons/bs';
import {
  AiOutlinePlus,
  AiOutlineInstagram,
  AiOutlineTwitter,
  AiOutlineHeart,
  AiOutlineSearch,
  AiOutlineCheck,
  AiOutlineInfoCircle,
  AiOutlineAlignRight,
  AiOutlineShoppingCart,
  AiFillStar, AiOutlineLock,
  AiOutlineMenu,
  AiFillEyeInvisible,
  AiFillEye


} from "react-icons/ai";
import { MdArrowBackIosNew, MdArrowForwardIos, MdLocationOn, MdOutlineClose, MdOutlineMailOutline } from "react-icons/md";


export const icons = {
  cross: <FiX />,
  eyeslash: <AiFillEyeInvisible />,
  eye: <AiFillEye />,
  Question: <BsQuestionCircleFill />,
  tick: <BsCheckCircleFill />,
  menu: <AiOutlineMenu />,
  comment: <FaComment />,
  play: <BsPlayFill />,
  share: <BsFillShareFill />,
  bsright: <BsArrowRight />,
  bsleft: <BsArrowLeft />,
  map: <MdLocationOn />,
  pinterest: <FaPinterestP />,
  wallet: <BiWalletAlt />,
  search: <AiOutlineSearch />,
  plus: <AiOutlinePlus />,
  facebook: <FaFacebookF />,
  linkedin: <FaLinkedinIn />,
  instagram: <AiOutlineInstagram />,
  twitter: <AiOutlineTwitter />,
  heart: <AiOutlineHeart />,
  leftarrow: <FiArrowLeft />,
  rightarrow: <FiArrowRight />,
  prevmd: <MdArrowBackIosNew />,
  nextmd: <MdArrowForwardIos />,
  check: <AiOutlineCheck />,
  info: <BsInfoCircle />,
  chevronright: <FiChevronRight />,
  chevrondown: <FiChevronDown />,
  close: <MdOutlineClose />,
  info: <AiOutlineInfoCircle />,
  pencil: <BiPencil />,
  time: <BiTimeFive />,
  circlenotch: <FaCircleNotch />,
  spinner: <FaSpinner />,
  navbtn: <AiOutlineAlignRight />,
  arrowright: <BsArrowRightShort />,
  mail: <IoMdMail />,
  cart: <AiOutlineShoppingCart />,
  playbutton: <BsFillPlayFill />,
  starfill: <AiFillStar />,
  user: <BiUserCircle />,
  lock: <AiOutlineLock />, email: <MdOutlineMailOutline />, phone: <BsTelephone />, telephone: <BsFillTelephoneFill />
};
