import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { isUserLoggedIn } from '@utils/basicReq'
import Cookies from "js-cookie";

const ProtectedRoutes = ({ component: Component, ...rest }) => {
  let path = Cookies.get('user')
  path = path ? JSON.stringify(path) : path;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [Component]);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (isUserLoggedIn()) {
          return (
            <Component {...props} {...rest} />
          );
        }
        return (
          <Redirect
            to={`/login?referrer=${props.location.pathname}`
            }
          // {...props}
          // lastPath={props.location}
          // to={{
          //   pathname: "/login",
          //   state: { from: props.location },
          // }}
          />
        );
      }}
    />
  )
};

export default ProtectedRoutes