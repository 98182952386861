import React from 'react'
import { FiAlertCircle } from 'react-icons/fi'
import { BsCheckCircleFill } from 'react-icons/bs'
import { FaTimes } from 'react-icons/fa'
import { useEffect } from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { HIDE_TOAST } from "@redux/action/actionConstants";

const CustomSnackBar = ({ open, autoHideDuration, onClose, severity, message }) => {
  const dispatch = useDispatch();
  let color = severity === 'error' ? 'bg-red-500' : 'bg-green-700';
  const [autoHide, setAutoHide] = useState(true);
  const handleClose = (event) => {

    dispatch({
      type: HIDE_TOAST,
    });
    setAutoHide(false);
  };
  useEffect(() => {
    setAutoHide(true);
    let interval = setTimeout(() => {
      handleClose();
    }, 3000)

    return () => {
      clearTimeout(interval);
    }
  }, [open])



  return (
    <div className={`fixed  w-[340px] lg:w-[370px]  bottom-4 ax-center z-[10000] ${autoHide && open ? 'flex' : "hidden"}`}>
      <div className={`flex gap-2 items-center px-4 rounded-md w-full py-4 ${color}`}>
        {severity === 'error' ? <FiAlertCircle className='text-white text-[25px]' /> : <BsCheckCircleFill className='text-white text-[25px]' />}

        <div className='px-2 font-semibold text-white  flex-1'>
          {message}
        </div>
        <button onClick={handleClose} className='m-0 p-0'><FaTimes className='text-white text-[22px]' /></button>


      </div>
    </div>
  )
}

export default CustomSnackBar