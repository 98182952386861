import React, { useEffect, useState } from 'react'
import HomeSlider from './HomeSlider'
import GLightbox from 'glightbox';
import { icons } from '@utils/icons';
import FirstFloorApartmentCard from '@components/cards/FirstFloorApartmentCard';
import BookAnAppointment from '@components/BookAnAppointment';
import NewsCard from '@components/cards/NewsCard';
import Cookies from 'js-cookie';
import { Redirect } from 'react-router-dom';
import { getReq } from '@utils/ApiHandler';
import { useHistory } from 'react-router-dom';

const Home = () => {
  //state
  const history = useHistory();

  let pathname = Cookies.get('pathname');
  // let user = Cookies.get('user');
  // user = user ? JSON.parse(user) : null;
  pathname = pathname ? JSON.parse(pathname) : null
  // const [selected, setSelected] = useState(null);
  const [apartment, setApartment] = useState([]);

  //hooks
  useEffect(() => {
    var lightbox = GLightbox();
    lightbox.on('open', (target) => {
    });
    // var lightboxDescription = GLightbox({
    //   selector: '.glightbox2'
    // });
    var lightboxVideo = GLightbox({
      selector: '.glightbox3'
    });
    lightboxVideo.on('slide_changed', ({ prev, current }) => {
      console.log('Prev slide', prev);
      console.log('Current slide', current);

      const { player } = current;

      if (player) {
        if (!player.ready) {
          // If player is not ready
          player.on('ready', (event) => {
            // Do something when video is ready
          });
        }

        player.on('play', (event) => {
          console.log('Started play');
        });

        player.on('volumechange', (event) => {
          console.log('Volume change');
        });

        player.on('ended', (event) => {
          console.log('Video ended');
        });
      }
    });;
  })

  useEffect(() => {
    getReq('/get-properties').then((res) => {
      if (res.status) {
        setApartment(res.data)
      } else {
        console.error(res);
      }
    })
  }, [])

  return (
    <>
      {(!pathname || pathname.pathname === '/') ?
        <>
          <header className='bg-primary-black2'>
            <div className="bg-[url('../public/images/home/slide-img.jpeg')] bg-cover bg-no-repeat relative">
              <div className='absolute inset-0 bg-black opacity-40 '></div>
              <HomeSlider />
            </div>
          </header>
          <section className="section-wrapper relative pb-[100px] lg:pb-[200px]">
            <img className='absolute top-[120px] left-[50px] xxl:left-[150px]' src="/images/home/alipes.png" alt="alipes" />
            <img className='absolute bottom-0 left-0' src="/images/home/shape.png" alt="alipes" />
            <div className="container">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 lg:gap-20 relative">
                <div>
                  <img className='lg:h-[540px] h-[320px] xxl:h-[641px] w-full lg:w-[500px] object-contain' src="/images/home/building-2.jpeg" alt="building-2" />
                </div>
                <div>
                  <h6 className='font-teko text-base text-primary-main uppercase mb-1'>Idea of Brickschain</h6>
                  <h4 className='heading-3 max-w-[350px]'>Brickschain</h4>
                  <p className="para">Welcome to Brickschain, where we're revolutionizing the way people invest in all forms of physical assets. Our company specializes in providing a wide range of asset management services. Commercial real estate(CRE) is most demanding asset class among all physical assets. We understand that every CRE is unique, which is why we offer customized solutions for different CRE investments.<br />

                  </p>
                  <ul className='my-8'>
                    {["Blockchain Enabled Security.", "Traceable, Transparent & Tampered proof ", "Trustworthy & Publicly Accessible"].map((item, index) => (
                      <li key={index} className='flex gap-5 items-start last:mb-0 mb-3 '> <img className='flex-shrink-0' src="/images/about/checked.svg" alt="checked" /> <span className='text-black font-bold leading-5 text-base tracking-c'>{item}</span> </li>
                    ))}

                  </ul>
                </div>

                {/*  */}
                {/* <div className='lg:absolute relative bg-primary-background2 py-11 px-14 w-full lg:bottom-[-110px] lg:left-[200px] max-w-[900px] flex flex-wrap justify-evenly gap-14'>
                  <div>
                    <img src="/images/home/c1.svg" alt="" />
                    <h5 className='heading-mxl leading-[30px] mt-4'>34,000</h5>
                    <p className='text-base text-primary-gray font-medium mt-1'>Total sqft Areas</p>
                  </div>
                  <div>
                    <img src="/images/home/c2.svg" alt="" />
                    <h5 className='heading-mxl leading-[30px] mt-4'>34,000</h5>
                    <p className='text-base text-primary-gray font-medium mt-1'>Total sqft Areas</p>
                  </div>
                  <div>
                    <img src="/images/home/c3.svg" alt="" />
                    <h5 className='heading-mxl leading-[30px] mt-4'>34,000</h5>
                    <p className='text-base text-primary-gray font-medium mt-1'>Total sqft Areas</p>
                  </div>
                  <div>
                    <img src="/images/home/c4.svg" alt="" />
                    <h5 className='heading-mxl leading-[30px] mt-4'>34,000</h5>
                    <p className='text-base text-primary-gray font-medium mt-1'>Total sqft Areas</p>
                  </div>

                </div> */}
              </div>

            </div>
          </section>
          {/* <section className='section-wrapper bg-primary-black2 '>
            <div className='text-center container'>
              <h3 className='text-white lg:text-[70px] lg:leading-[70px] text-[38px] leading-[40px] tracking-[0.07em] font-teko '>SCHEDULE A BUILDING VISIT</h3>
              <button onClick={() => history.push('/contact')} className="btn2 btn-primary mx-auto mt-8 text-base">Send A Request</button>
            </div>
          </section> */}
          <section className='bg-white relative'>
            <img className='absolute top-0 left-0' src="/images/home/shape-2.png" alt="shape2" />
            <div className="max-w-[1180px] mx-auto  min-h-[450px] relative z-10 bg-video">

              <a className="glightbox4 tm-pulse-shadow v-btn w-[80px] h-[80px] center rounded-full bg-white text-black text-3xl absolute right-[128px] bottom-[93px] z-[2]">
                {icons.play}
              </a>
              {/* <img className='absolute right-0 bottom-0 z-[1]' src="/images/home/triangle.svg" alt="" /> */}
            </div>
            <div className='section-wrapper relative z-10'>
              <div className='container '>
                <div className="text-center">
                  <h6 className='font-teko text-base text-primary-main uppercase mb-1'>choose your investment</h6>
                  <h4 className='heading-3'>Our Recent Investment Opportunity</h4>
                </div>
                <div className="flex gap-8 justify-center items-center mt-12 flex-wrap">
                  <FirstFloorApartmentCard item={apartment[0]} />
                  <FirstFloorApartmentCard item={apartment[1]} />
                  <FirstFloorApartmentCard item={apartment[2]} />
                </div>

              </div>
            </div>

          </section>
          <section className="section-wrapper bg-cover bg-no-repeat bg-[url('../public/images/home/bg-1.svg')]">
            <div className="container">
              <div className="text-center">
                <h4 className='heading-3'>SELECT AVAILABILITY</h4>
              </div>
              <div className='mt-12 overflow-x-auto'>
                {/*  table */}
                <table className="table-auto lg:w-full tablet:w-[200%]  w-[300%]">
                  <thead>
                    <tr>
                      <th className='uppercase py-6 bg-primary-black text-white w-[20%]  font-teko text-md font-medium tracking-c whitespace-nowrap  text-center'>Title</th>
                      <th className='uppercase py-6 bg-primary-black text-white w-[12%] font-teko text-md font-medium tracking-c whitespace-nowrap  text-center'>Property Category</th>
                      <th className='uppercase py-6 bg-primary-black text-white w-[12%] font-teko text-md font-medium tracking-c whitespace-nowrap  text-center'>Valuation</th>
                      <th className='uppercase py-6 bg-primary-black text-white w-[12%] font-teko text-md font-medium tracking-c whitespace-nowrap  text-center'>Rental Returns</th>
                      <th className='uppercase py-6 bg-primary-black text-white w-[12%] font-teko text-md font-medium tracking-c whitespace-nowrap  text-center'>Total</th>
                      <th className='uppercase py-6 bg-primary-black text-white w-[12%] font-teko text-md font-medium tracking-c whitespace-nowrap  text-center'>Sold Out</th>
                      <th className='uppercase py-6 bg-primary-black text-white w-[20%] font-teko text-md font-medium tracking-c whitespace-nowrap  text-center'>Explore</th>
                    </tr>
                  </thead>
                  <tbody className='bg-white'>
                    {apartment?.map((item, index) => (
                      <tr key={index} className='py-4 flex  border-b-[2px] border-b-primary-background2 items-center'>
                        <td className='uppercase w-[20%] font-teko py-4 text-md text-center text-primary-main '>{item?.title}</td>
                        <td className='uppercase w-[12%] font-teko py-4 text-md text-center text-primary-gray '>{item?.property_category}</td>
                        <td className='uppercase w-[12%] font-teko py-4 text-md text-center text-primary-gray '>{item?.valuation}</td>
                        <td className='uppercase w-[12%] font-teko py-4 text-md text-center text-primary-gray '>{item?.rental_returns}</td>
                        <td className='uppercase w-[12%] font-teko py-4 text-md text-center text-primary-gray '>{item?.total_number_of_fraction}</td>

                        <td className='uppercase w-[12%] font-teko py-4 text-md text-center text-primary-gray'>{item?.sold_out}</td>
                        <td className='uppercase w-[20%] font-teko py-4 text-md text-center text-primary-gray flex justify-center '>
                          <button className='text-base font-medium btn2 px-8 btn-primary bg-[#d9d9d9] text-primary-gray uppercase' onClick={() => history.push(`/apartments-details/${item?.property_id}`)}>View Details </button>
                        </td>
                      </tr>
                    ))}


                  </tbody>
                </table>
              </div>
            </div>
          </section>
          <section className='grid grid-cols-12 px-4 lg:px-0'>
            <div className="bg-white section-wrapper lg:col-span-7 col-span-12">
              <div className=''>
                <div className=" max-w-[450px] mx-auto lg:mr-[240px]">
                  <h6 className='font-teko text-base text-primary-main uppercase mb-1'>The Innovation</h6>
                  <h4 className='heading-3 tracking-[0.07em]'>A New Concept In Property Investment</h4>
                  <p className="para mt-4 mb-6">Whether you're looking to invest, sell, rent, or manage the property, Alpha Brickschain has the expertise, technology, and dedication to provide you with a personalized and innovative solution.</p>
                  <div className="flex gap-6 mb-6">
                    <img className='flex-shrink-0' src="/images/home/a1.svg" alt="" />
                    <div>
                      <h6 className='font-teko text-lg'>Blockchain as Backbone</h6>
                      <p className="para">
                        Our innovative blockchain-based technology is the backbone of our services. It ensures that our client's property transactions are secure, transparent, and tamper-proof.
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-6 ">
                    <img className='flex-shrink-0' src="/images/home/a2.svg" alt="" />
                    <div>
                      <h6 className='font-teko text-lg'>Get in touch</h6>
                      <p className="para">
                        Our team of experienced professionals is passionate about providing exceptional service that exceeds our client's expectations.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div className="bg-primary-main section-wrapper lg:col-span-5 col-span-12 flex items-center">
              <div className='h-[350px] xxl:h-[480px] w-full  lg:max-w-[880px] lg:ml-[-200px]'>
                <img className='absolute-img object-contain' src="/images/home/building-1.jpeg" alt="building-1" />

              </div>

            </div>

          </section>

          {/* <section className="section-wrapper bg-primary-background2 lg:pt-[240px] pt-[70px] pb-[50px] lg:pb-[200px] ">
            <div className="container">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <div>
                  <div className='max-w-[420px]'>
                    <h6 className='font-teko text-base text-primary-main uppercase mb-1'>nearby areas</h6>
                    <h4 className='heading-3 tracking-[0.05em] text-black '>Latest news updates & articles</h4>
                  </div>
                </div>
                <div>
                  <p className="para leading-8">Interior of volumes, space, air, proportion, with certain light and mood. These interiors are meant to last forever. You need to be sure there isn't anything embarrassing hidden in the middle of text.</p>
                </div>
              </div>
            </div>

          </section>
          <section className='relative lg:top-[-120px]'>
            <div className="flex justify-center gap-7 flex-wrap  container ">
              {Array(3).fill('2').map((item, index) => (
                <NewsCard key={index} />
              ))}
            </div>
          </section> */}

        </> :
        <Redirect to={pathname} />
      }
    </>
  )
}

export default Home