import React from 'react'
import ENV from '@utils/env';
import { useHistory } from 'react-router-dom';

const FirstFloorApartmentCard = ({ item }) => {

    const history = useHistory();

    return (<>
        {(item) &&
            <div className='max-w-[370px] w-full lg:w-[calc(100%/3-32px)] tablet:w-[calc(100%/2-28px)] shadow-1' onClick={() => history.push(`/apartments-details/${item?.property_id}`)} style={{ 'cursor': 'pointer' }}>
                <div className='h-[268px]'>
                    <img className='absolute-img' src={item?.featured_image ? `${ENV.BASE_URL}/property_image/${item?.featured_image}` : "/images/dummy.jpg"} alt="" />
                </div>
                <div className='px-7 pt-7 text-center'>
                    <h5 className='font-teko text-xl uppercase font-semibold'>{item?.title}</h5>
                    <p className="para font-teko uppercase">{item?.property_category}</p>
                    <p className="para font-teko uppercase">Price - {item?.fraction_price}</p>
                </div>
            </div>
        }
    </>

    )
}

export default FirstFloorApartmentCard