import React from 'react'
import PageHeader from './../../components/PageHeader';

const PrivacyPolicy = () => {
  return (
    <>
      <PageHeader location={'PRIVACY POLICY'} title={'PRIVACY POLICY'} />
      <section className='section-wrapper'>
        <div className="container">
          <h3 className='heading-3 text-center'>Privacy Policy</h3>

          <p className='text-center'>This Privacy Policy describes how we collect, use, and disclose information about you
            through our website ( www.brickschain.tech ).</p>
          <ul className='mt-10'>
            <li className='mb-3'>
              <h6 className='heading-5 '>Information We Collect</h6>
              <p>We collect personal information that you provide to us, such as your name, email
                address, and other contact information, when you submit a contact form or sign up for
                our newsletter. We may also collect information about your use of the Site, such as your
                IP address, browser type, and other technical information.</p>
            </li>
            <li className='mb-3'>
              <h6 className='heading-5 '>Use of Information</h6>
              <p>We use the information we collect to provide and improve the Site and our services, to
                respond to your inquiries and requests, and to send you marketing communications that
                we believe may be of interest to you.<br /><br />We may also share your information with third-party service providers who perform
                services on our behalf, such as email marketing and web analytics. These third-party
                service providers are bound by confidentiality obligations and are prohibited from using
                your information for any other purpose.<br /><br />In addition, we may disclose your information if required by law or if we believe that such
                disclosure is necessary to protect our rights or the rights of others.</p>
            </li>
            <li className='mb-3'>
              <h6 className='heading-5 '>Data Security</h6>
              <p>We take data security seriously and have implemented reasonable and appropriate
                technical, administrative, and physical measures to protect your information from
                unauthorized access, use, and disclosure. For example, we use encryption technology to
                protect sensitive information transmitted online.<br /><br />However, please note that no method of transmission over the internet or method of
                electronic storage is completely secure, and we cannot guarantee the absolute security
                of your information.</p>
            </li>
            <li className='mb-3'>
              <h6 className='heading-5 '>Cookies</h6>
              <p>We use cookies on the Site to collect information about your browsing behavior and
                preferences. A cookie is a small text file that is stored on your computer or mobile device
                when you visit the Site. You can disable cookies through your browser settings, but
                please note that some features of the Site may not function properly if cookies are
                disabled.</p>
            </li>
            <li className='mb-3'>
              <h6 className='heading-5 '>Your Choices</h6>
              <p>You may opt out of receiving marketing communications from us by following the
                unsubscribe instructions included in the communication. Please note that even if you opt
                out of receiving marketing communications, we may still send you transactional or
                administrative communications about the Site and our services.</p>
            </li>
            <li className='mb-3'>
              <h6 className='heading-5 '>Children's Privacy</h6>
              <p>The Site is not intended for use by children under the age of 18, and we do not
                knowingly collect personal information from children under the age of 18. If we become
                aware that we have collected personal information from a child under the age of 18, we
                will take steps to delete such information.</p>
            </li>
            <li className='mb-3'>
              <h6 className='heading-5 '>Your Consent</h6>
              <p>By using the Site, you consent to our collection, use, and disclosure of information about
                you as described in this Privacy Policy. If you do not agree with the terms of this Privacy
                Policy, please do not use the Site.</p>
            </li>
            <li className='mb-3'>
              <h6 className='heading-5 '>Changes to this Privacy Policy</h6>
              <p>We may update this Privacy Policy from time to time. The updated Privacy Policy will be
                posted on the Site with the updated effective date. We encourage you to review the
                Privacy Policy periodically.</p>
            </li>
            <li className='mb-3'>
              <h6 className='heading-5 '>Contact Us</h6>
              <p>If you have any questions or concerns about this Privacy Policy or our privacy practices,
                please contact us at [ contact information].</p>
            </li>
          </ul>
        </div>
      </section>
    </>
  )
}

export default PrivacyPolicy