import React, { useEffect } from 'react'
import { setUserDetails, showToast } from "@redux/action"
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import NotFound from "@pages/NotFound/NotFound";
import Home from "@pages/Home/Home";
import ProtectedRoutes from "./ProtectedRoutes";
import Profile from "@pages/profile";
import UserRoutes from './UserRoutes';
import './index.css';
import CustomSnackBar from '@components/CustomSnackBar';
import Navbar from '@components/layout/Navbar';
import About from '@pages/About';
import Footer from '@components/layout/Footer';
import Faq from '@pages/Faq/Faq';
import ApartmentsDetails from '@pages/ApartmentsDetails/ApartmentsDetails';
import Apartments from '@pages/Apartments';
import ContactUs from '@pages/ContactUs/ContactUs';
import MyProperties from '@pages/MyProperties'
import Products from '@pages/Products/Products';
import CookiePolicy from '@components/popup/CookiesPolicy';
import { getApiReq } from '@utils/ApiHandler';
import Cookies from 'js-cookie';
import { useState } from 'react';
import ResetPassword from '@pages/Login/ResetPassword';
import BankDetails from '@pages/profile/BankDetails';
import ForgotPassword from '@pages/Login/ForgotPassword';
import LoginSignup from '@pages/Login/LoginSignup';
import { isUserLoggedIn } from '@utils/basicReq';
import PrivacyPolicy from '@pages/PrivacyPolicy/PrivacyPolicy';
import TermsAndConditions from '@pages/TermsAndConditions/TermsAndConditions';
import PurchaseFraction from '@pages/PurchaseFraction.js/PurchaseFraction';

export const AuthContext = React.createContext({});

const Routes = ({ getUserDetailsApi }) => {
  const { pathname } = useLocation()

  const search = useLocation().search;
  const verifyTrue = new URLSearchParams(search).get('emailVerified');

  const dispatch = useDispatch();
  const { type, message, isVisible } = useSelector(
    (state) => state.toastReducer
  );
  const { username, email, isEmailVerified } = useSelector((state) => state.userReducer)

  let verifyEmail = Cookies.get('isVerify')
  verifyEmail = verifyEmail ? JSON.parse(verifyEmail) : null
  let isEmail = Cookies.get('email')
  isEmail = isEmail ? JSON.parse(isEmail) : null

  const [marque, setMarque] = useState(false);


  useEffect(() => {
    if (pathname) {
      window.scrollTo(0, 0)
      Cookies.remove('pathname')
    }
  }, [pathname])

  useEffect(() => {
    if (isEmail) {
      setMarque(true);
    }
    if (verifyEmail) {
      setMarque(false)
    }
  }, [isEmail, verifyEmail])

  useEffect(() => {
    if (verifyTrue) {
      dispatch(showToast({ type: 'success', message: 'Email Verified Successfully' }));
    }
  }, [])

  return (
    <>
      <CustomSnackBar
        open={isVisible}
        autoHideDuration={2500}
        severity={type}
        message={message}
      >
      </CustomSnackBar>


      {pathname == '/login' || pathname == '/forgot-password' || pathname.includes('/reset-password/') ? null : <Navbar />}
      {(!isEmailVerified && isUserLoggedIn()) && <div className='bg-primary-black2'>
        <p className="blink py-3">
          An Email has been sent on your Registered Email id. Please Verify Your email. </p>
      </div>}

      <Switch>
        <Route path={'/'} exact component={Home} />
        <Route path={'/about'} exact component={About} />
        <Route path={'/faq'} exact component={Faq} />
        <Route path={'/privacy-policy'} exact component={PrivacyPolicy} />
        <Route path={'/terms-condition'} exact component={TermsAndConditions} />
        <Route path={'/apartments-details/:id'} exact component={ApartmentsDetails} />
        <Route path={'/apartments'} exact component={Apartments} />
        <Route path={'/contact'} exact component={ContactUs} />
        <Route path={'/product-detail/:id'} exact component={ApartmentsDetails} />
        <Route path={'/commercial-properties'} exact component={Products} />
        <Route path={'/reset-password/:id'} exact component={ResetPassword} />
        <Route path={'/forgot-password'} exact component={ForgotPassword} />
        <Route path={'/login'} exact component={LoginSignup} />
        <Route path={'/add-bank-details'} exact render={(props) => <BankDetails getUserDetailsApi={getUserDetailsApi} />} />
        <Route path={"/user"} exact render={(props) => <UserRoutes getUserDetailsApi={getUserDetailsApi} />}
        />

        <ProtectedRoutes path={'/profile'} exact component={Profile} />
        <ProtectedRoutes path={'/my-properties'} exact component={MyProperties} />
        <ProtectedRoutes path={'/purchase-token/:hash/:id'} exact component={PurchaseFraction} />


        <Route path={'/*'} exact component={NotFound} />
      </Switch>
      {pathname == '/login' || pathname == '/forgot-password' || pathname.includes('/reset-password/') ? null : <Footer />}
      <CookiePolicy />
    </>
  );
}

const App = () => {
  const [user, setUser] = React.useState({})
  const dispatch = useDispatch();
  let userDetail = Cookies.get('user');
  userDetail = userDetail ? JSON.parse(userDetail) : null;
  const { updateDetails } = useSelector((state) => state.userReducer);

  const getUserDetailsApi = async () => {
    if (userDetail?.token && isUserLoggedIn()) {
      getApiReq('/user/get-user-details').then((res) => {
        if (res.status) {
          dispatch(setUserDetails({ username: res?.data?.data?.user_full_name, email: res?.data?.data?.user_email, phone: res?.data?.data?.user_phone, isEmailVerified: res?.data?.data?.is_email_verified === 0 ? false : true, isPhoneVerified: res?.data?.data?.is_phone_verified === 0 ? false : true, isKYC: res?.data?.data?.is_kyc_completed === 0 ? false : true, aadharCard: res?.data?.data?.user_aadhar_no, pancard: res?.data?.data?.user_pancard, bankVerificationStatus: res?.data?.data?.bank_verification_status == "ACCEPTED" ? true : res?.data?.data?.bank_verification_status == null ? null : false, whitelisted: res?.data?.data?.status == "APPROVED" ? true : false }))
          Cookies.set('isVerify', JSON.stringify({ isVerified: true }))
        }
      })
    }
  }

  useEffect(() => {
    (async () => {
      await getUserDetailsApi();
    })()
  }, [updateDetails])

  return (
    <Router>
      <AuthContext.Provider value={{ user, setUser }}>
        <Routes getUserDetailsApi={getUserDetailsApi} />
      </AuthContext.Provider>
    </Router>
  )
}
export default App;
