import Loader from '@components/Loader';
import { showToast } from '@redux/action';
import { getReq, postReq } from '@utils/ApiHandler';
import { ResetPasswordValidation } from '@utils/Auth';
import { icons } from '@utils/icons'
import { isYupError, parseYupError } from '@utils/Yup';
import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

const ResetPassword = () => {
  const [errors, setErrors] = useState(null);
  const [checkPassword, setCheckPassword] = useState(false)
  const [form, setForm] = useState({
    password: '',
    confirmPassword: ''
  });
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    })
    setErrors({
      ...errors,
      [name]: ''
    })
    setCheckPassword(false)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      await ResetPasswordValidation.validate(form, {
        abortEarly: false,
      });
      if (form.password === form.confirmPassword) {
        postReq(`/change-password/${id}`, { "password": form.password }).then((res) => {
          if (res.status) {
            dispatch(showToast({ type: 'success', message: 'Password change successfully.' }))
            history.push('/')
          }
        }).catch((e) => {
          console.error('error', e);
        })
      }
      else {
        setCheckPassword(true)
        dispatch(showToast({ type: 'error', message: 'Please enter both password are same.' }))
      }
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.error('error');
      }
    }
  }
  return (
    <>
      <Loader />
      <div className='section-wrapper center bg-primary-black2 min-h-screen'>
        <div className="container center ">
          <div className="form flex overflow-hidden   max-w-[500px] w-full text-primary-gray rounded border-primary-border ">
            <div className="flex relative w-full">
              <form className='h-fit w-[100%] flex-shrink-0 p-12 bg-white' action="">
                <h3 className='heading-2 text-black text-center relative'>
                  Reset-Password
                  <div className="absolute bottom-0 right-0 left-0 mx-auto w-28 h-[2px] bg-primary-main  rounded-md "></div>
                </h3>
                <div className="mt-12">
                  <label className='font-teko text-md text-primary-black font-medium' htmlFor="">Password</label>
                  <div className="relative">
                    <input type="password" name='password' placeholder='type your password' className='pl-10 bg-white h-12 border-solid border-t-0 border-x-0 border-b border-b-primary-gray' onChange={handleChange} />
                    <span className='ay-center left-1 text-lg'>{icons.lock}</span>
                  </div>
                  {errors && <span className='text-red-500'>{errors.password}</span>}
                </div>
                <div className="mt-6">
                  <label className='font-teko text-md text-primary-black font-medium' htmlFor="">Confirm Password</label>
                  <div className="relative">
                    <input type="password" name='confirmPassword' placeholder='type your password' className='pl-10 bg-white h-12 border-solid border-t-0 border-x-0 border-b border-b-primary-gray' onChange={handleChange} />
                    <span className='ay-center left-1 text-lg'>{icons.lock}</span>
                  </div>
                  {errors && <span className='text-red-500'>{errors.confirmPassword}</span>}
                </div>
                {checkPassword && <span className='text-red-500'>Please enter both password are same</span>}
                <div className="mt-12 ">
                  <button type='submit' onClick={handleSubmit} className='btn2  w-full rounded-full text-2xl font-semibold text-primary-black uppercase bg-primary-background2 hover:bg-primary-main hover:text-white'>Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ResetPassword
