import React from 'react'

const PageHeader = ({ location, title }) => {
  return (
    <header className="min-h-[300px] xxl:min-h-[700px]  text-white flex  items-center justify-center flex-col mb-5 relative">
      <span className='font-teko text-base uppercase block mb-1'>Home&nbsp; /&nbsp;{location}</span>
      <h2 className='heading-2'>{title}</h2>

    </header>
  )
}

export default PageHeader