/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react'
// import { useSelector } from 'react-redux';
import { getApiReq, getReq, postApiReq, postReq } from '@utils/ApiHandler';
import { AddBankDetailsValidation } from '@utils/Auth';
import Loader from '@components/Loader';
import { showToast, setUserDetails } from '@redux/action';
import { isYupError, parseYupError } from '@utils/Yup';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { icons } from '@utils/icons';
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tooltip,
} from '@chakra-ui/react'
import Cookies from 'js-cookie';
export default function BankDetails({ getUserDetailsApi }) {
  // const { username, email, phone, isEmailVerified, aadharCard, bank, pancard, isPhoneVerified } = useSelector((state) => state.userReducer);

  const dispatch = useDispatch();
  const history = useHistory();
  const [errors, setErrors] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [details, setDetails] = useState([]);
  const [openForm, setOpenForm] = useState(false);
  const [whiteListStatus, setWhiteListStatus] = useState("");
  let sign = Cookies.get('sign');
  let userData = Cookies.get('user-data')

  const [bankData, setBankData] = useState({
    accountHolderName: '',
    bankName: '',
    accountNo: null,
    bankIfsc: null,
    branchName: '',
    cheque: ''
  })

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBankData({
      ...bankData,
      [name]: value
    })
    setErrors({
      ...errors,
      [name]: ''
    })
  }

  const getImageUrl = async (data) => {
    var image = false
    await postApiReq("/user/upload-cheque", data)
      .then((res) => {
        if (res.status) {
          image = res.data
        }
        else {
          image = false
        }
      }
      )
      .catch((e) => {
        console.error(e)
      })

    return image
  }

  const handleImageUpload = async (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const { name, files } = e.target;
      const file = files[0];
      if (file.size) {
        if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'application/pdf') {
          const data = new FormData();
          data.append("file", file);
          const image = await getImageUrl(data);
          if (image.data) {
            handleChange({ target: { name: 'cheque', value: image.data } });
          }
        } else {
          // dispatch(showToast({ type: 'error', message: 'Please upload accepted file.' }));
          return false;
        }
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      await AddBankDetailsValidation.validate(bankData, {
        abortEarly: false,
      })
      setIsLoading(true);
      postApiReq('/user/add-bank-details', bankData).then((res) => {
        if (res.status) {
          if (res.data.status === 1) {
            setIsLoading(false);
            setBankData({
              accountHolderName: '',
              bankName: '',
              accountNo: null,
              bankIfsc: null,
              branchName: '',
              cheque: ''
            })
            dispatch(showToast({ type: 'success', message: "Details has been sent for Verification" }));
            getUserDetailsApi();
            handleGetBankDetails();

          } else {
            setIsLoading(false);
            dispatch(showToast({ type: 'error', message: "An error Occured, Please try again later." }))
          }
        }
        else {
          setIsLoading(false);
          dispatch(showToast({ type: 'error', message: "An error Occured, Please try again later." }))
        }
      })

    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.error('error');
      }
    }
  };

  const handleUpdate = async (e, id) => {
    e.preventDefault();

    const data = { ...bankData, id };
    try {
      setErrors({});
      console.log(data);
      await AddBankDetailsValidation.validate(data, {
        abortEarly: false,
      })
      setIsLoading(true);
      postApiReq('/user/update-bank-details', data).then((res) => {
        if (res.status) {
          if (res.data.status === 1) {
            setIsLoading(false);
            setOpenForm(false);
            setBankData({
              accountHolderName: '',
              bankName: '',
              accountNo: null,
              bankIfsc: null,
              branchName: '',
              cheque: ''
            })
            dispatch(showToast({ type: 'success', message: "Details have Updated and for Verification" }));
            getUserDetailsApi();
            handleGetBankDetails();

          } else {
            setIsLoading(false);
            dispatch(showToast({ type: 'error', message: "An error Occured, Please try again later." }))
          }
        }
        else {
          setIsLoading(false);
          dispatch(showToast({ type: 'error', message: "An error Occured, Please try again later." }))
        }
      })

    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.error('error');
      }
    }

  };

  const handleGetBankDetails = () => {
    getApiReq('/user/get-user-bank-details').then((res) => {
      if (res.status) {
        if (res.data.status === 1) {
          setDetails(res.data.data);
          // if (res.data.data[0]?.bank_verification_status === 'ACCEPTED') {
          setBankData({
            accountHolderName: res.data.data[0]?.account_holder_name,
            bankName: res.data.data[0]?.bank_name,
            accountNo: res.data.data[0]?.account_no,
            bankIfsc: res.data.data[0]?.bank_ifsc_code,
            branchName: res.data.data[0]?.branch_name,
            // cheque: res.data.data[0]?.cheque_image
          })
          // }

        }
        else {
          console.error(res.data.error);
        }
      } else {
        console.error('Error');
      }
    })
  };

  const handleRequestWhitelist = async () => {
    setIsLoading(true);

    const { ethereum } = window;
    await ethereum.request({ method: "eth_requestAccounts", })

    if (whiteListStatus === 'APPROVED') {
      setIsLoading(false);
      dispatch(showToast({ type: 'error', message: 'Already Whitelist User' }));
    }
    else if (whiteListStatus === 'PENDING') {
      setIsLoading(false);
      dispatch(showToast({ type: 'error', message: "Approval Pending" }));
    }
    else {
      getApiReq('/user/request-whitelisting').then((res) => {
        if (res.status) {
          setIsLoading(false);
          if (res.data.status === 1) {
            dispatch(showToast({ type: 'success', message: 'Requested For Whitelisting' }));
            getWhiteListUser();
          }
          else {
            dispatch(showToast({ type: 'error', message: res.data.error }));
          }
        } else {
          dispatch(showToast({ type: 'error', message: 'Something Went Wrong!' }));
        }
      })
    }
  }

  const getWhiteListUser = async () => {
    getApiReq('/user/get-whitelist-user').then((res) => {
      if (res.status) {
        if (res.data.status === 1) {
          setWhiteListStatus(res.data.data.status);
        } else {
          if (res.data.error === 'Requesting Not Found') {
            setWhiteListStatus('Request For WhiteListing')

          } else {
            console.error(res.data.error);
          }
        }
      } else {
        dispatch(showToast({ type: 'error', message: 'Something Went Wrong!' }));
      }
    })

  }

  useEffect(() => {
    handleGetBankDetails();
  }, [])

  useEffect(() => {
    getWhiteListUser();
  }, [])


  return (
    <>
      <Loader isLoading={isLoading} />
      <div className='py-12'>
        <div className="container">
          <div className='flex justify-end items-center gap-2'>
            <button className='btn btn-primary' onClick={() => handleRequestWhitelist()}>{whiteListStatus === 'APPROVED' ? "You are Whitelist User" : whiteListStatus === "PENDING" ? 'Approval Pending' : 'Request For Whitelisting'} </button>
            <div className=''>
              <Tooltip label={whiteListStatus === 'APPROVED' ? 'Please request to Whitelist your Wallet Address' : whiteListStatus === 'PENDING' ? 'Whitelisting Approval is Pending' : 'Please request to Whitelist your Wallet Address'}>
                <span className='text-primary-main text-md'>{icons.Question}</span>
              </Tooltip>
            </div>
          </div>
          {(details.length > 0 && (details[0].bank_verification_status === 'PENDING' || details[0].bank_verification_status === 'ACCEPTED' || details[0].bank_verification_status === 'REJECTED')) &&
            <div className='py-10 overflow-x-auto'>
              <div className='w-[200%] tablet:w-full'>
                <TableContainer >
                  <Table variant='simple'>
                    {/* <TableCaption>Imperial to metric conversion factors</TableCaption> */}
                    <Thead>
                      <Tr>
                        <Th>Bank Name</Th>
                        <Th>Account Holder Name</Th>
                        <Th>Account No.</Th>
                        <Th>Status</Th>
                        {(details[0].bank_verification_status === 'ACCEPTED' || details[0].bank_verification_status === 'REJECTED') &&
                          <Th>Options</Th>
                        }
                      </Tr>
                    </Thead>
                    <Tbody>
                      {details?.map((detail, index) => (
                        <Tr key={index}>
                          <Td>{detail?.bank_name}</Td>
                          <Td>{detail?.account_holder_name}</Td>
                          <Td>{detail?.account_no}</Td>
                          <Td>{detail?.bank_verification_status}</Td>
                          {(details[0].bank_verification_status === 'ACCEPTED' || details[0].bank_verification_status === 'REJECTED') &&
                            <Td> <button type='submit' onClick={() => setOpenForm(true)} className='btn btn-primary text-md'>Update</button></Td>
                          }
                        </Tr>
                      ))
                      }

                    </Tbody>
                    {/* <Tfoot>
                 <Tr>
                   <Th>To convert</Th>
                   <Th>into</Th>
                   <Th isNumeric>multiply by</Th>
                 </Tr>
               </Tfoot> */}
                  </Table>
                </TableContainer>
              </div>
            </div>
          }

          {openForm &&
            <form action="" className='max-w-[400px] w-full mx-auto  flex flex-col gap-4'>
              <div className='flex flex-col gap-2'>
                <label htmlFor="">Account Holder Name</label>
                <input onChange={handleChange} name="accountHolderName" type="text" placeholder='Account Holder Name' value={bankData.accountHolderName} />
              </div>
              {errors && <span className='text-red-500'>{errors.accountHolderName}</span>}
              <div className='flex flex-col gap-2'>
                <label htmlFor="">Bank Name</label>
                <div className='flex items-center gap-3'>
                  <input onChange={handleChange} name="bankName" type="text" placeholder='Bank Name' value={bankData.bankName} />

                </div>
              </div>
              {errors && <span className='text-red-500'>{errors.bankName}</span>}
              <div className='flex flex-col gap-2'>
                <label htmlFor="">Bank Account Number</label>
                <div className='flex items-center gap-3'>
                  <input onChange={handleChange} name="accountNo" type="text" placeholder='Bank Account Number' value={bankData.accountNo} />

                </div>
              </div>
              {errors && <span className='text-red-500'>{errors.accountNo}</span>}
              <div className='flex flex-col gap-2'>
                <label htmlFor="">Bank IFSC Code</label>
                <div className='flex items-center gap-3'>
                  <input onChange={handleChange} name="bankIfsc" type="text" placeholder='IFSC Code' value={bankData.bankIfsc} />
                </div>
              </div>
              {errors && <span className='text-red-500'>{errors.bankIfsc}</span>}
              <div className='flex flex-col gap-2'>
                <label htmlFor="">Branch Name</label>
                <div className='flex items-center gap-3'>
                  <input onChange={handleChange} name="branchName" type="text" placeholder='Branch Name' value={bankData.branchName} />
                </div>
              </div>
              {errors && <span className='text-red-500'>{errors.branchName}</span>}
              <label htmlFor="">Cheque Image</label>
              <input onChange={handleImageUpload} type="file" />
              {errors && <span className='text-red-500'>{errors.cheque}</span>}

              <button type='submit' onClick={(e) => handleUpdate(e, details[0].id)} className='btn2 btn-primary text-md'>Update</button>
            </form>
          }
          {(((details.length > 0 && (details[0].bank_verification_status !== 'PENDING' && details[0].bank_verification_status !== 'ACCEPTED' && details[0].bank_verification_status !== 'REJECTED')) || (details.length === 0))) &&
            <form action="" className='max-w-[400px] w-full mx-auto  flex flex-col gap-4'>
              <div className='flex flex-col gap-2'>
                <label htmlFor="">Account Holder Name</label>
                <input onChange={handleChange} name="accountHolderName" type="text" placeholder='Account Holder Name' value={bankData.accountHolderName} />
              </div>
              {errors && <span className='text-red-500'>{errors.accountHolderName}</span>}
              <div className='flex flex-col gap-2'>
                <label htmlFor="">Bank Name</label>
                <div className='flex items-center gap-3'>
                  <input onChange={handleChange} name="bankName" type="text" placeholder='Bank Name' value={bankData.bankName} />

                </div>
              </div>
              {errors && <span className='text-red-500'>{errors.bankName}</span>}
              <div className='flex flex-col gap-2'>
                <label htmlFor="">Bank Account Number</label>
                <div className='flex items-center gap-3'>
                  <input onChange={handleChange} name="accountNo" type="text" placeholder='Bank Account Number' value={bankData.accountNo} />

                </div>
              </div>
              {errors && <span className='text-red-500'>{errors.accountNo}</span>}


              <div className='flex flex-col gap-2'>
                <label htmlFor="">Bank IFSC Code</label>
                <div className='flex items-center gap-3'>
                  <input onChange={handleChange} name="bankIfsc" type="text" placeholder='IFSC Code' value={bankData.bankIfsc} />

                </div>
              </div>
              {errors && <span className='text-red-500'>{errors.bankIfsc}</span>}
              <div className='flex flex-col gap-2'>
                <label htmlFor="">Branch Name</label>
                <div className='flex items-center gap-3'>
                  <input onChange={handleChange} name="branchName" type="text" placeholder='Branch Name' value={bankData.branchName} />

                </div>
              </div>
              {errors && <span className='text-red-500'>{errors.branchName}</span>}
              <label htmlFor="">Cheque Image</label>
              <input onChange={handleImageUpload} type="file" />
              {errors && <span className='text-red-500'>{errors.cheque}</span>}

              <button type='submit' onClick={handleSubmit} className='btn2 btn-primary text-md'>Submit</button>
            </form>
          }



        </div>

      </div>
    </>
  )
}
