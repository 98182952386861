import * as types from './actionConstants';

/**
 * Notification Popup
 */
export const showToast = (payload) => ({
  type: types.SHOW_TOAST,
  payload
})

/** USER DETAILS*/

export const setUserDetails = (payload) => ({
  type: types.SET_USER_DETAILS,
  payload
});

export const setVerified = (payload) => ({
  type: types.SET_VERIFIED,
  payload
});
