const dev = {
  BASE_URL: (process.env.NODE_ENV === 'development') ? 'http://localhost:8001' : 'https://alpha-api.brickschain.tech',
  RAZORPAY_API_KEY: 'rzp_test_ehZNx9eLi6Jtzm',
  RAZORPAY_SECRET_KEY: '6pUeKEGKlToeigjHmvIwgGPZ',
  CONTRACT_ADDRESS: '0x4300BB96d9aeE0fAD62b4c2Aeb614c258E374f20',
  ASSET_CONTRACT: '0xc7b72c41bb8dac3a1e12daa2ec91e5782858dbcb',
  PUBLIC_KEY: '0x610e84262A278e0aca36d32E08b822CC80FA5b85',
  RPC_URL: 'https://data-seed-prebsc-1-s1.binance.org:8545/',

  /*BANK DETAILS */
  ACCOUNT_HOLDER_NAME: 'Brickschain Pvt Ltd',
  BANK_NAME: 'Kotak Mahindra Bank',
  BRANCH_NAME: 'Narayangaon, Pune',
  ACCOUNT_NO: '2746364972',
  IFSC_CODE: 'KKBK0001754'
}

const ENV = dev;

export default ENV;