import React from 'react'
import OurAgentCard from '@components/cards/OurAgentCard';
import PageHeader from '@components/PageHeader';

const About = () => {
  return (
    <main >
      <div className="bg-[url('../public/images/about.jpg')] bg-cover bg-no-repeat bg-left relative">
        <div class="absolute inset-0 bg-black opacity-40 "></div>
        <PageHeader location={'About'} title={'About us'} />
      </div>
      {/* <BuildingAdds about /> */}
      <section className=" grid  grid-cols-1 md:grid-cols-2 gap-14 md:gap-10 lg:gap-20">
        <div className='bg-primary-black2 relative h-[350px] md:h-auto'>
          <img className='absolute-img' src="/images/home/skyscrapers-450793.jpg" alt="" />
          <div className='absolute bg-primary-main  w-5 h-[80%] ay-center right-[-18px] lg:flex hidden'></div>

        </div>
        <div className='bg-primary-brownlight section-wrapper px-4 lg:px-0 lg:pl-24'>
          <div>
            <h6 className='font-teko uppercase text-base text-primary-main'>Smart Investment</h6>
            <h2 className='heading-3 text-white max-w-[402px]'>Buy Fractional Property, Diversify Investment</h2>
            <p className='leading-8 text-primary-gray2 mt-4 max-w-[502px]'>Brickschain allows our buyer/seller to buy, sell and rent properties in fraction. Each transaction is recorded at blockchain where data is traceable, publicly accessible and tamper proof. We work for commercial properties and provide our clients with flexibility to purchase properties that meet their specific needs and requirements.</p>
            <p className='leading-8 text-primary-gray2 mt-4 max-w-[502px]'>In 3 simple steps you can purchase fractions - </p>
            <div className='flex tablet:flex-row flex-col gap-8 mt-8 items-start'>
              <div className=''>
                <img className='max-h-[118px] object-cover max-w-[198px]' src="/images/home/skyscrapers-450793.jpg" alt="" />
              </div>
              <ul>
                {["Sign-up & Connect your Wallet", "Complete your E-KYC, Whitelist your account", "Choose your property & buy"].map((item, index) => (
                  <li className='flex gap-2 items-start last:mb-0 mb-3 '> <img className='flex-shrink-0' src="/images/about/checked.svg" alt="checked" /> <span className='text-white font-semibold leading-5 text-base tracking-c'>{item}</span> </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
      <section className='section-wrapper'>
        <div className="text-center">
          <h6 className='font-teko uppercase text-base text-primary-main tracking-c'>Our Team</h6>
          <h2 className='heading-3 text-black tracking-c'>meet our teams</h2>
          <p className='leading-8 text-primary-gray2 text-center mt-4 max-w-[502px] mx-auto'>We pride ourselves on building long-lasting relationships with our clients, providing cutting edge technology & based on honesty, integrity, and mutual trust.</p>
        </div>
        <div className="container mt-12">
          <div className="flex flex-wrap justify-center gap-[60px]">
            <OurAgentCard name='Dr. Anil Mundhe' img='/images/shriprasad.jpeg' linkdin='' />
            <OurAgentCard name='Shriprasad Wable' img='/images/anil.jpg' linkdin='' />
          </div>
        </div>

      </section>
      {/* <BookAnAppointment /> */}
    </main>
  )
}

export default About