import React, { useState } from 'react'
import PageHeader from '@components/PageHeader'
import { useDispatch } from 'react-redux'
import { showToast } from '@redux/action'

const ContactUs = () => {
  const dispatch = useDispatch();
  const [messageData, setMessageData] = useState({
    name: '',
    email: '',
    phone: null,
    subject: '',
    message: ''
  })

  return (
    <div>
      <div className="bg-[url('../public/images/contact.jpg')] bg-cover bg-no-repeat bg-left relative">
        <div class="absolute inset-0 bg-black opacity-40 "></div>
        <PageHeader location={'contact us'} title={'Contact us'} />
      </div>
      <section className="section-wrapper ">
        <div className="container">
          <div className="text-center">
            <h6 className='font-teko uppercase text-base text-primary-main tracking-c'>get in touch with us</h6>
            <h2 className='heading-3 text-black tracking-c'>Write us anytime</h2>
          </div>
          <form action="" className='max-w-[800px] mx-auto mt-12'>
            <div className="grid grid-cols-1 tablet:grid-cols-2 gap-5">
              <div><input type="text" onChange={(e) => setMessageData({ ...messageData, name: e.target.value })} placeholder='Your Name' /></div>
              <div><input type="text" onChange={(e) => setMessageData({ ...messageData, email: e.target.value })} placeholder='Email Address' /></div>
              <div><input type="number" onChange={(e) => setMessageData({ ...messageData, phone: e.target.value })} placeholder='Phone Number' /></div>
              <div><input type="text" onChange={(e) => setMessageData({ ...messageData, subject: e.target.value })} placeholder='Subject' /></div>
              <div className='tablet:col-span-2'><textarea onChange={(e) => setMessageData({ ...messageData, message: e.target.value })} name="" id="" className=' px-6 py-4 h-[150px]' placeholder='Write Message'></textarea></div>
              <div className='tablet:col-span-2 flex justify-center pt-4'>
                <button onClick={(e) => { e.preventDefault(); dispatch(showToast({ 'type': 'success', 'message': 'Message Has been sent Successfully' })) }} className='btn2 btn-primary'>Send a Message</button>
              </div>
            </div>
          </form>
        </div>
      </section>
      <section>
        <iframe title='map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3780.9088860172874!2d73.8377618!3d18.623167499999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2b9873aaded19%3A0xad371d460b1af5b0!2sSTPI%20CoE%20-%20Motion!5e0!3m2!1sen!2sin!4v1677481936480!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </section>
    </div>
  )
}

export default ContactUs