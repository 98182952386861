import Loader from '@components/Loader';
import { showToast } from '@redux/action';
import { postReq } from '@utils/ApiHandler';
import { ForgotPasswordValidation } from '@utils/Auth';
import { icons } from '@utils/icons'
import { isYupError, parseYupError } from '@utils/Yup';
import React from 'react'
import { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

export default function ForgotPassword() {
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState(null);
  const [form, setForm] = useState({
    email: ''
  });
  const dispatch = useDispatch();
  const history = useHistory()
  const handleChange = (e) => {
    const { name, value } = e.target
    setForm({
      ...form,
      [name]: value
    });
    setErrors({
      ...errors,
      [name]: ''
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setErrors({});
      await ForgotPasswordValidation.validate(form, {
        abortEarly: false,
      });
      setIsLoading(true);
      postReq('/forgot-password', form).then((res) => {
        if (res.status) {
          dispatch(showToast({ type: 'success', message: 'Reset-password link send your email.' }))
          setIsLoading(false)
        }
      }).catch((e) => {
        console.error('error', e);
      });
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.error('error');
      }
    }
  }

  const goBack = () => {
    history.goBack();
  }
  return (
    <>
      <Loader isLoading={isLoading} />
      <div className='section-wrapper center bg-primary-black2 min-h-screen'>
        <div className="container center ">
          <div className="form flex overflow-hidden   max-w-[500px] w-full text-primary-gray rounded border-primary-border ">
            <div className="flex relative w-full">

              <form className='h-fit w-[100%] flex-shrink-0 p-12 bg-white' action="">
                <h3 className='heading-2 text-black text-center relative'>
                  Forgot-Password
                  <div className="absolute bottom-0 right-0 left-0 mx-auto w-28 h-[2px] bg-primary-main  rounded-md "></div>
                </h3>
                <div className="mt-12">
                  <label className='font-teko text-md text-primary-black font-medium' htmlFor="">Email</label>
                  <div className="relative">
                    <input type="text" name='email' placeholder='type your email' className='pl-10 bg-white h-12 border-solid border-t-0 border-x-0 border-b border-b-primary-gray' onChange={handleChange} />
                    <span className='ay-center left-1 text-lg'>{icons.email}</span>
                  </div>
                  {errors && <span className='text-red-500'>{errors.email}</span>}
                </div>
                <div className="mt-12 ">
                  <button type='submit' onClick={handleSubmit} className='btn2  w-full rounded-full text-2xl font-semibold text-primary-black uppercase bg-primary-background2 hover:bg-primary-main hover:text-white'>Submit</button>
                </div>
                {/* <div className="mt-2 ">
                  <button onClick={goBack} className='btn2  w-full rounded-full text-2xl font-semibold text-primary-black uppercase bg-primary-background2 hover:bg-primary-main hover:text-white'>Back</button>
                </div> */}
              </form>

            </div>
          </div>
        </div>
      </div>
    </>
  )
}
