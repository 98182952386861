import { navlinks } from '@utils/constants';
import React, { useState } from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import { icons } from './../../utils/icons';
import { useLocation } from 'react-router-dom';
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button
} from '@chakra-ui/react'
import SearchModal from '@components/popup/SearchModal';
import LoginModal from '@components/popup/LoginModal';
import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { getApiReq } from '@utils/ApiHandler';
import { useDispatch } from 'react-redux';
import { setUserDetails } from '@redux/action';

const Navbar = () => {
  const [popup, setPopup] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isSearch, setIsSearch] = useState(false);
  const { pathname } = useLocation();
  const { username } = useSelector((state) => state.userReducer)
  const dispatch = useDispatch();
  const history = useHistory();

  const handleLogout = () => {
    getApiReq('/user/logout').then((res) => {
      if (res.status) {
        Cookies.remove('user');
        Cookies.remove('user-data');
        Cookies.remove('email');
        Cookies.remove('isVerify');
        Cookies.remove('newPath');
        localStorage.clear();
        dispatch(setUserDetails({ username: '', email: '' }))
        history.push('/')
      }
    }).catch((e) => {
      console.error('error', e);
    });
    Cookies.remove('user');
    Cookies.remove('pathname');
    Cookies.remove('email')
  }

  const handleProfile = () => {
    history.push('/user');
  }

  const handleOpenPopup = () => {
    setPopup(true);
  }
  const handleClosePopup = () => {
    setPopup(false)
  }

  return (
    <>    <nav className='w-full sticky top-[-35px] lg:top-[-35px] xl:top-[-42px] z-[100]'>
      <div className="flex justify-between">
        <div className='flex gap-3 items-center bg-primary-black2 text-white xl:py-3 py-2 px-4 lg:px-14'>
          <a className='text-md' href="https://www.facebook.com/brickschainproperty" target="_blank" rel="noopener noreferrer">{icons.facebook}</a>
          <a className='text-md' href="https://twitter.com/brickschain" target="_blank" rel="noopener noreferrer">{icons.twitter}</a>
          <a className='text-md' href="https://www.instagram.com/brickschain/?igshid=YmMyMTA2M2Y%3D" target="_blank" rel="noopener noreferrer">{icons.instagram}</a>
        </div>
        <div className="flex flex-1 justify-between gap-4 bg-primary-brownlight items-center xl:py-3 py-2 text-primary-gray2 px-4 lg:px-8 ">
          <span></span>
          <div className='hidden lg:flex gap-4'>
            <div className='flex items-center gap-1'><span className='text-primary-main text-md'>{icons.map}</span><a href="https://www.google.com/maps/place/STPI+CoE+-+Motion/@18.6231675,73.8355731,17z/data=!3m1!4b1!4m6!3m5!1s0x3bc2b9873aaded19:0xad371d460b1af5b0!8m2!3d18.6231675!4d73.8377618!16s%2Fg%2F11r7qc31vt" target="_blank" rel="noopener noreferrer">STPI, Gala no. 5, Electronics Sadan III, MIDC Indl Estate, Bhosari, Pune</a></div>
            <div className='flex items-center gap-2'> <span className='text-primary-main'>{icons.mail}</span>support@brickschain.tech</div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between gap-4 bg-primary-black2 text-white xl:py-3 py-2 px-2 lg:px-14">
        <NavLink to={'/'}> <div className='max-w-[200px]'> <img src="/images/logo/ira-logo1.png" alt="" className='absolute-img' /> </div> </NavLink>
        <div className='flex-1 lg:flex justify-center gap-12 hidden '>
          {navlinks.map((link, index) => (
            <NavLink to={link.path} key={link.path + index} className=" uppercase font-thin font-teko text-lg">{link.title}</NavLink>
          ))}

        </div>
        <div className="flex items-center gap-4 text-white relative">
          <span className='absolute ay-center w-[1.5px] bg-primary-gray h-[55%] left-[-20px] '></span>
          {/* <button onClick={() => setIsSearch(true)} className='text-lg cursor-pointer'>{icons.search}</button> */}
          {/* <NavLink to={'/cart'} className='center'>
            <button className='text-lg cursor-pointer'>{icons.cart}</button>
          </NavLink> */}
          {/* <button className='uppercase hidden lg:center py-4 px-12 text-primary-black font-teko font-medium text-md bg-white ml-3 '>Schedule a visit</button> */}
          {username ? <Menu >
            <MenuButton as={Button} className='flex-shrink-0 [&>span]:flex [&>span]:items-center  [&>span]:gap-2  !bg-transparent !text-xl  center ' _focus={{ boxShadow: 'none' }} rightIcon={icons.chevrondown}>
              <span> {icons.user}</span><span className='text-sm'>{username}</span>
            </MenuButton>
            <MenuList className='!bg-primary-black !hover:bg-transparent'>
              <MenuItem _focus={{ background: 'transparent' }} _active={{ background: 'transparent' }} className=' !active:bg-transparent !hover:bg-transparent text-white ' _hover={{ background: 'transparent' }} onClick={handleProfile}>Profile</MenuItem>
              <MenuItem _focus={{ background: 'transparent' }} _active={{ background: 'transparent' }} className=' !active:bg-transparent !hover:bg-transparent text-white ' _hover={{ background: 'transparent' }} onClick={() => history.push('/my-properties')}>My Properties</MenuItem>
              <MenuItem _focus={{ background: 'transparent' }} _active={{ background: 'transparent' }} className=' !active:bg-transparent !hover:bg-transparent text-white ' _hover={{ background: 'transparent' }} onClick={handleLogout}>Logout</MenuItem>

            </MenuList>
          </Menu> :
            <div className='flex gap-2 items-center'>
              <button onClick={handleOpenPopup} className='btn tablet:px-10 px-6  rounded-full font-semibold text-primary-black uppercase bg-primary-background2 hover:bg-primary-main hover:text-white leading-4 tablet:leading-0'>Get Started</button>
              <button onClick={handleOpenPopup} className='btn tablet:px-10 px-6  rounded-full font-semibold text-primary-black uppercase bg-primary-background2 hover:bg-primary-main hover:text-white'>Login</button>
            </div>}
          <button onClick={onOpen} className='lg:hidden center w-12 h-12 bg-primary-background2 text-primary-main text-lg'>{icons.menu}</button>
        </div>
      </div>

    </nav>
      <Drawer
        isOpen={isOpen}
        placement='left'
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent className='!bg-primary-black'>
          <DrawerCloseButton className='text-white text-xl !top-4' _focus={{ boxShadow: 'none' }} />
          <DrawerHeader >
            <NavLink to={'/'}> <div className='w-[150px] '>
              <img src="/images/logo/ira-logo.png" alt="" className='absolute-img' /></div> </NavLink>
          </DrawerHeader>

          <DrawerBody>
            <div className='flex flex-col '>
              {navlinks.map((link, index) => (

                <NavLink onClick={onClose} to={link.path} key={link.path + index} className={`uppercase py-2 w-full hover:bg-primary-black2 px-2 font-thin text-white font-teko text-lg ${pathname === link.path && 'bg-primary-black2'}`}>{link.title}</NavLink>
              ))}
            </div>
          </DrawerBody>
          <DrawerFooter>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
      <SearchModal isSearch={isSearch} setIsSearch={setIsSearch} />
      <LoginModal />
      <LoginModal login={popup} setLogin={handleClosePopup} />
    </>
  )
}

export default Navbar