import PageHeader from '@components/PageHeader'
import React from 'react'
import { Link } from 'react-router-dom'
import { icons } from '@utils/icons';
const NotFound = () => {
  return (
   <>
    <PageHeader location={'404 error'} title='404 error'/>
    <section className="section-wrapper">
      <div className="container center">
       <div className='text-center max-w-[480px]'>
          <span className='text-primary-main text-[100px] lg:text-[200px] font-bold'>404</span>
          <h3 className='heading-3  lg:text-6xl -mt-6'>Sorry we can't find that page!</h3>
          <p className='text-primary-gray mt-2'>The page you are looking for was never existed.</p>
          <form className='flex justify-between bg-primary-background2 pr-8 h-[52px] mt-8'>
            <input type="text" className='border-0 text-primary-gray' placeholder='search here' />
            <button className='text-md'>{icons.search}</button>
          </form>
          <button className='btn btn-primary uppercase mx-auto mt-6 h-[52px] px-20'>Back to Home</button>

       </div>

      </div>
    </section>
   </>
  )
}
export default NotFound