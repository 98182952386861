/* eslint-disable no-undef */
import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import Loader from '@components/Loader';
import { setUserDetails, showToast } from '@redux/action';
import { getReq, postReq } from '@utils/ApiHandler';
import { LoginValidation, SignupValidation } from '@utils/Auth';
import { icons } from '@utils/icons';
import { isYupError, parseYupError } from '@utils/Yup';
import Cookies from 'js-cookie';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

const LoginModal = ({ login, setLogin }) => {
  const [toggle, setToggle] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [errors, setErrors] = useState(null)
  const [showPassword, setShowPassword] = useState(false);
  const [form, setForm] = useState({
    email: '',
    full_name: '',
    phone: '',
    password: '',
    type: 'USER'
  });
  const [loginForm, setLoginForm] = useState({
    email: '',
    password: ''
  });
  const dispatch = useDispatch();
  const history = useHistory()
  const { pathname } = useLocation();
  let sign = Cookies.get('sign');
  // const queryParam = queryString.parse(history.location.search);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value
    })
    setLoginForm({
      ...form,
      [name]: value
    })
    setErrors({
      ...errors,
      [name]: ''
    })
  }


  //Connect Metamask
  const connectMetaMaskWallet = async (data) => {
    setIsLoading(true);
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: '0x61' }], // chainId must be in hexadecimal numbers
      });

    }
    catch (error) {
      if (error) {
        if (error.code === 4902) {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: 'BSC Testnet',
                chainId: '0x61',
                nativeCurrency: { name: 'BNB', decimals: 18, symbol: 'BNB' },
                rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545']
              }
            ]
          });

        } else {
          setIsLoading(false);
          dispatch(
            showToast({
              type: "error",
              message: "Metamask Wallet not Found ",
            }))
        }
      }
      return false
    }
    try {
      /**Check metamask install or not  */
      if (Boolean(ethereum && ethereum.isMetaMask)) {
        try {
          // Will open the MetaMask UI
          await ethereum
            .request({ method: "eth_requestAccounts" })
            .then((res) => {
              getReq(`/user/authenticate/${data['id']}/${res[0]}`)
                .then((authResponse) => {
                  if (authResponse.status) {
                    ethereum
                      .request({
                        method: "personal_sign",
                        params: [`${authResponse.data.data}`, res[0]],
                      })
                      .then((sigRes) => {
                        if (sigRes) {
                          // Cookies.set('sign', sigRes)
                          // dispatch(closeWalletModal());
                          postReq(`/user/authenticate`, {
                            address: res[0],
                            signature: sigRes,
                          })
                            .then((authTokenRes) => {
                              if (authTokenRes.status) {
                                dispatch(
                                  showToast({
                                    type: "success",
                                    message: "Metamask wallet connected",
                                  })
                                );
                                Cookies.set('pathname', JSON.stringify({ pathname: pathname }))
                                Cookies.set('user', JSON.stringify({ token: data.token }))
                                Cookies.set('user-data', JSON.stringify({ type: data.type, isUserLogin: true, name: data.name, email: data.email, id: data.id }))
                                dispatch(setUserDetails({ username: data.name, email: data.email, updateDetails: true }));
                                dispatch(showToast({ type: 'success', message: 'User login successfully.' }))
                                setToggle(true)
                                setLogin()
                                setIsLoading(false);
                                history.push(pathname ? pathname : '/')
                                setIsLoading(false);
                              } else {
                                dispatch(
                                  showToast({
                                    type: "error",
                                    message: authTokenRes.error,
                                  })
                                );
                                setIsLoading(false);
                              }
                            })
                            .catch((e) => {
                              setIsLoading(false);
                              dispatch(
                                showToast({
                                  type: "error",
                                  message: e?.authResponse?.data,
                                })
                              );
                            });
                        }
                      })
                      .catch((e) => {
                        setIsLoading(false);
                        dispatch(
                          showToast({ type: "error", message: e?.message })
                        );
                      });
                  } else {
                    setIsLoading(false);
                    dispatch(
                      showToast({ type: "error", message: authResponse.error })
                    );
                  }
                })
                .catch((e) => {
                  setIsLoading(false);
                  dispatch(
                    showToast({ type: "error", message: e?.authResponse?.data })
                  );
                });
            });
        } catch (error) {
          console.error(error);
          setIsLoading(false);
        }
      } else {
        dispatch(showToast({ type: "error", message: "Metamask Not Install" }));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      dispatch(showToast({ type: "error", message: "Unable to Connect with Metamask Wallet" }))
    }

  };

  //Register API
  const handleSubmit = async (e) => {
    e.preventDefault()
    let data = {
      email: form.email,
      full_name: form.full_name,
      phone: Number(form.phone),
      password: form.password,
      type: 'USER'
    }
    try {
      setErrors({});
      await SignupValidation.validate(form, {
        abortEarly: false,
      });
      setIsLoading(true)
      postReq('/register', data).then((res) => {
        if (res.status) {
          if (res.data.status === 1) {
            connectMetaMaskWallet(res.data.data);
          }
          else {
            setIsLoading(false)
            dispatch(showToast({ type: 'error', message: res?.data?.error }))
          }
        }
        else {
          dispatch(showToast({ type: 'error', message: res.data.error }))
          setIsLoading(false)
        }
      })
        .catch((e) => {
          console.error('error', e);
        });
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.error('error');
      }
    }
  }

  //Signin API
  const handleLoginSubmit = async (e) => {
    e.preventDefault()
    try {
      setErrors({});
      await LoginValidation.validate(loginForm, {
        abortEarly: false,
      });
      setIsLoading(true)
      postReq('/signin', loginForm).then((res) => {
        if (res.status) {
          if (res.data.status === 1) {
            connectMetaMaskWallet(res.data.data);
          } else {
            setIsLoading(false);
            dispatch(showToast({ type: 'error', message: res.data.error }))
          }
        }
        else {
          dispatch(showToast({ type: 'error', message: "Something went wrong, Please contact to administrator" }))
          setIsLoading(false)
        }
      })
        .catch((e) => {
          console.log('error', e);
        });
    } catch (error) {
      if (isYupError(error)) {
        setErrors(parseYupError(error));
      } else {
        console.log('error');
      }
    }
  }

  return (
    <>
      <Loader isLoading={isLoading} />
      <Modal
        isCentered
        size={'md'}
        isOpen={login}
        onClose={setLogin}
        motionPreset='slideInBottom'
      >
        <ModalOverlay className='!backdrop-blur-sm' />
        <ModalContent className='mx-2'>
          <ModalBody className='!p-0 rounded-md overflow-hidden'>
            <div className="form flex overflow-hidden   max-w-[500px] w-full text-primary-gray rounded border-primary-border ">
              <div className="flex relative w-full">
                {toggle ?
                  <form className='h-fit w-[100%] flex-shrink-0 p-12 bg-white' action="">
                    <h3 className='heading-2 text-black text-center relative'>
                      Login
                      <div className="absolute bottom-0 right-0 left-0 mx-auto w-28 h-[2px] bg-primary-main  rounded-md "></div>
                    </h3>
                    <div className="mt-12">
                      <label className='font-teko text-md text-primary-black font-medium' htmlFor="">Email</label>
                      <div className="relative">
                        <input type="text" name='email' value={loginForm.email} placeholder='Enter Your Email' className='pl-10 bg-white h-12 border-solid border-t-0 border-x-0 border-b border-b-primary-gray' onChange={handleChange} />
                        <span className='ay-center left-1 text-lg'>{icons.user}</span>
                      </div>
                      {errors && <span className='text-red-500'>{errors.email}</span>}
                    </div>
                    <div className="mt-6">
                      <label className='font-teko text-md text-primary-black font-medium' htmlFor="">Password</label>
                      <div className="relative">
                        <input type={showPassword ? 'text' : 'password'} name='password' value={loginForm.password} placeholder='Enter Your Password' className='pl-10 bg-white h-12 border-solid border-t-0 border-x-0 border-b border-b-primary-gray' onChange={handleChange} />

                        <span className='ay-center pointer-events-none left-1 text-lg'>{icons.lock}</span>
                        <span onClick={() => setShowPassword(!showPassword)} className='ay-center pointer-events-auto right-0 text-lg cursor-pointer z-10'>{showPassword ? icons.eyeslash : icons.eye}</span>
                      </div>
                      {errors && <span className='text-red-500'>{errors.password}</span>}
                    </div>
                    <div className="mt-12 ">
                      <button type='submit' onClick={handleLoginSubmit} className='btn2  w-full rounded-full text-2xl font-semibold text-primary-black uppercase bg-primary-background2 hover:bg-primary-main hover:text-white'>Login</button>
                    </div>
                    <div className="center mt-9">
                      <span onClick={() => history.push('/forgot-password')} className='cursor-pointer text-primary-black font-semibold'>Forgot Password</span>
                    </div>
                    <div className="center mt-9">
                      <span onClick={() => setToggle(!toggle)} className='cursor-pointer text-primary-black font-semibold'>Don't have an account? SignUp</span>
                    </div>
                  </form> :
                  <form className={`w-full flex-shrink-0   p-12 relative bg-white`} action="">
                    <h3 className='heading-2 text-black text-center relative '>
                      Sign Up
                      <div className="absolute bottom-0 right-0 left-0 mx-auto w-36 h-[2px] bg-primary-main  rounded-md "></div>
                    </h3>
                    <div className="mt-12">
                      <label className='font-teko text-md text-primary-black font-medium' htmlFor="">Full name</label>
                      <div className="relative">
                        <input type="text" placeholder='Enter Your Name' name='full_name' value={form.full_name} className='pl-10 bg-white h-12 border-solid border-t-0 border-x-0 border-b border-b-primary-gray' onChange={handleChange} />
                        <span className='ay-center left-1 text-lg'>{icons.user}</span>
                      </div>
                      {errors && <span className='text-red-500'>{errors.full_name}</span>}
                    </div>
                    <div className="mt-6">
                      <label className='font-teko text-md text-primary-black font-medium' htmlFor="">Email</label>
                      <div className="relative">
                        <input type="email" placeholder='Enter Your Email' name='email' value={form.email} className='pl-10 bg-white h-12 border-solid border-t-0 border-x-0 border-b border-b-primary-gray' onChange={handleChange} />
                        <span className='ay-center left-1 text-lg'>{icons.email}</span>
                      </div>
                      {errors && <span className='text-red-500'>{errors.email}</span>}
                    </div>
                    <div className="mt-6">
                      <label className='font-teko text-md text-primary-black font-medium' htmlFor="">Phone</label>
                      <div className="relative">
                        <input type="text" placeholder='Enter Your Number' name='phone' value={form.phone} className='pl-10 bg-white h-12 border-solid border-t-0 border-x-0 border-b border-b-primary-gray' onChange={handleChange} />
                        <span className='ay-center left-1 text-lg'>{icons.phone}</span>
                      </div>
                      {errors && <span className='text-red-500'>{errors.phone}</span>}
                    </div>
                    <div className="mt-6">
                      <label className='font-teko text-md text-primary-black font-medium' htmlFor="">Password</label>
                      <div className="relative">
                        <input type={showPassword ? 'text' : 'password'} placeholder='Enter Your Password' name='password' value={form.password} className='pl-10 bg-white h-12 border-solid border-t-0 border-x-0 border-b border-b-primary-gray' onChange={handleChange} />
                        <span className='ay-center left-1 text-lg'>{icons.lock}</span>
                        <span onClick={() => setShowPassword(!showPassword)} className='ay-center pointer-events-auto right-0 text-lg cursor-pointer z-10'>{showPassword ? icons.eyeslash : icons.eye}</span>
                      </div>
                      {errors && <span className='text-red-500'>{errors.password}</span>}
                    </div>
                    <div className="mt-12 ">
                      <button type='submit' onClick={handleSubmit} className='btn2  w-full rounded-full text-2xl font-semibold text-primary-black uppercase bg-primary-background2 hover:bg-primary-main hover:text-white'>sign up</button>
                    </div>
                    <div className="center mt-9">
                      <span onClick={() => setToggle(!toggle)} className='cursor-pointer text-primary-black font-semibold'>Already a registered member? Login</span>
                    </div>
                  </form>}
              </div>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default LoginModal