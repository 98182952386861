import React, { useState } from 'react'
import Loader from '@components/Loader';
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from '@chakra-ui/react';


const FractionPaymentPopup = ({ isOpen, onClose, toggle, handleChange, setFractionCount, fractionCount, amount, fractionCalculation, buyFraction, apartment, errors, setBankStatement, setEligibleForPayment, setAmount }) => {

    const [isLoading, setIsLoading] = useState(false)
    return (
        <>
            <Loader isLoading={isLoading} />
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent className='mx-2 '>
                    <ModalHeader className='!pb-0 !mt-2 !mb-6' >
                        <h2 className='heading-4'>Buy Fractions</h2>

                    </ModalHeader>
                    <ModalCloseButton _focus={{ boxShadow: 'none' }} />
                    <ModalBody>
                        {/* <Lorem count={2} /> */}
                        <div className='flex gap-2 justify-between mb-2'>
                            <h2 className=' heading-5'> Price Per Fraction</h2>
                            <span className='font-semibold'>{apartment?.fraction_price} &nbsp;INR</span>
                        </div>
                        <div className="flex items-center justify-between gap-4 mb-2">
                            <label className=' flex-shrink-0 font-semibold text-base' htmlFor="">Fraction to buy</label>
                            <div className='max-w-[200px]'>
                                <input name='fraction_count' type='number' className='text-base h-12 px-4 font-medium placeholder-shown:text-sm' placeholder='Enter how many fractions you want to buy...' onChange={(e) => setFractionCount(e.target.value)} />
                                {/* {errors && <span className='text-red-500'>{errors.fraction_count}</span>} */}
                            </div>
                        </div>
                        <div className='flex gap-2 justify-between mb-1'>
                            <h2 className=' heading-5'> Sub Total</h2>
                            <span className='font-semibold'>{amount}</span>
                        </div>
                        <div className='flex gap-2 justify-between mb-2'>
                            <h2 className=' heading-5'>Total</h2>
                            <span className='font-semibold'>{amount}</span>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <div className='flex justify-end gap-2'>
                            <button className='btn btn-primary bg-red-400'
                                onClick={() => {
                                    onClose();
                                    setEligibleForPayment(false);
                                    setAmount(0)
                                    setFractionCount(0)
                                }}
                            >
                                Close
                            </button>
                            <button className='btn btn-primary '
                                onClick={() => {
                                    setBankStatement(true);
                                    onClose();
                                }}>
                                buy
                            </button>
                        </div>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default FractionPaymentPopup