import ENV from '@utils/env';
import { icons } from '@utils/icons'
import React from 'react'
import { useHistory } from 'react-router-dom'

const ProductCard = ({ appartment }) => {
    const history = useHistory()
    return (
        <div className='tablet:max-w-[300px] max-w-full shadow-1' >
            <div className='relative h-[220px] w-full cursor-pointer' onClick={() => history.push(`/product-detail/${appartment?.property_id}`)}>
                <img className='absolute-img' src={`${ENV.BASE_URL}/property_image/${appartment?.featured_image}`} alt="img" />

            </div>
            <div className='py-4 px-4 text-center border-[2px] border-t-0 border-primary-background2'>
                <div className="flex gap-1 justify-center">
                    {Array(5).fill('2').map((item, index) => (
                        <span key={index} className='text-primary-main text-sm'>{icons.starfill}</span>
                    ))}
                </div>
                <h5 className='font-teko font-semibold text-lg mt-2'>{appartment?.title}</h5>
                <span className='text-primary-gray text-base font-teko'>{appartment?.fraction_price}</span>
                <button onClick={() => history.push(`/product-detail/${appartment?.property_id}`)} className="btn  bg-primary-black text-white hover:bg-primary-main mt-3 mx-auto text-xs leading-[40px] [text-transform:none]"> View Details</button>
            </div>
        </div>
    )
}

export default ProductCard