/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useState } from 'react'
import PageHeader from '@components/PageHeader'
import { useDispatch } from 'react-redux'
import { showToast } from '@redux/action'
import { getApiReq } from '@utils/ApiHandler'
import { useHistory } from 'react-router-dom'
import Loader from '@components/Loader';
import axios from 'axios'
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react'

const MyProperties = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [userProperties, setUserProperties] = useState([]);
  const [metaData, setMetaData] = useState([]);
  const [propertyImages, setPropertyImages] = useState([]);
  const [tokenCount, setTokenCount] = useState([]);
  const [isLoading, setIsLoading] = useState(true);


  const getPropertyImages = async (data) => {
    for (let i = 0; i < data.length; i++) {
      const uri = data[i].purchased_token_uri.split(',')
      const count = data[i].purchased_token.split(',').length;
      setTokenCount(tokenCount => [...tokenCount, count]);

      const response = await axios.get(uri[0]).catch(err => err);

      if (response instanceof Error) {
        console.error({ type: 'error', message: 'Unable to Fetch NFT at the moment' })
      } else {
        setMetaData(metaData => [...metaData, response.data]);
        // setPropertyImages(propertyImages => [...propertyImages, response.data.image]);
        setIsLoading(false);
      }
    }
  }

  const getUserProperties = async () => {
    setUserProperties([])
    setMetaData([])
    setPropertyImages([])
    getApiReq('/user/get-user-nft').then((res) => {
      if (res.status) {
        if (res.data.status === 1) {
          setUserProperties(res?.data?.data);
          getPropertyImages(res?.data?.data);
        }
        else {
          setIsLoading(false);
          dispatch(showToast({ type: 'error', message: res?.data?.error }));
        }
      } else {
        setIsLoading(false);
        dispatch(showToast({ type: 'error', message: 'Something Went Wrong' }));
      }
    })
  }

  useEffect(() => {
    getUserProperties()
  }, [])

  return (
    <>
      {/* <Loader isLoading={isLoading} /> */}
      <div className='bg-primary-black'>
        <PageHeader location={'My Properties'} title={'My Properties'} />
      </div>
      {(userProperties.length > 0) ?
        <section>
          <div className="container">
            <div className='gap-5 py-20'>
              <TableContainer >
                <Table variant='simple'>
                  <Thead>
                    <Tr>
                      <Th>Property Name</Th>
                      <Th>No. of Tokens</Th>
                      <Th>Category</Th>
                      <Th>Estimated Appreciation</Th>
                      <Th>Location</Th>
                      <Th>Fraction Price</Th>

                    </Tr>
                  </Thead>
                  {userProperties?.map((property, index) => (
                    <Tbody key={index}>
                      <Tr >
                        <Td>{metaData[index]?.name}</Td>
                        <Td>{tokenCount[index]}</Td>
                        <Td>{metaData[index]?.attributes[0].value}</Td>
                        <Td>{metaData[index]?.attributes[1].value}</Td>
                        <Td>{metaData[index]?.attributes[2].value}</Td>
                        <Td>{metaData[index]?.attributes[3].value}</Td>
                      </Tr>
                    </Tbody>
                  ))
                  }
                </Table>
              </TableContainer>
            </div>
          </div>
        </section>
        :
        <div className="container py-5" >
          <p className='text-2xl text-center font-semibold'>
            You haven't Invested in any Property Yet!
          </p>
          <button onClick={() => history.push('/commercial-properties')} className="btn  bg-primary-black text-white hover:bg-primary-main mt-8 mx-auto text-md leading-[40px] [text-transform:none]">Invest in  Properties Now</button>
        </div>

      }


    </>
  )
}

export default MyProperties;