import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import * as DOMPurify from 'dompurify';
import { useParams, useHistory } from 'react-router-dom';
import LoginModal from '@components/popup/LoginModal'
import PageHeader from '@components/PageHeader';
import { getApiReq, getReq, postReq } from '@utils/ApiHandler';
import ENV from '@utils/env';
import Loader from '@components/Loader';
import Cookies from 'js-cookie';
import { showToast } from '@redux/action';
import { setApproval } from '@utils/web3';

const PurchaseFraction = () => {

  const [login, setLogin] = useState(false);
  const [apartment, setApartment] = useState(null);
  const [cleanDescription, setCleanDescription] = useState("");
  const { id, hash } = useParams();
  const [isLoading, setIsLoading] = useState(true);

  const sign = Cookies.get('sign');

  const dispatch = useDispatch();
  const history = useHistory();

  let user = Cookies.get('user');
  user = user ? JSON.parse(user) : null

  let pathname = Cookies.get('pathname');
  pathname = pathname ? JSON.parse(pathname) : null

  let userData = Cookies.get('user-data');
  userData = userData ? JSON.parse(userData) : null

  const handleClosePopup = () => {
    setLogin(false);
  }

  const funcReturnCleanDesc = () => {
    return { __html: cleanDescription };
  }

  const purchaseFractions = async () => {
    setIsLoading(true);
    getApiReq(`/user/purchase-fractions/${hash}`).then(async (res) => {
      if (res.status) {
        if (res?.data?.status === 1) {
          console.log('NFT transferred Successfully');
          setIsLoading(false);
          dispatch(showToast({ type: "success", message: 'NFT Transferred Successfully' }));
          history.push('/my-properties');
        } else {
          if (res?.data?.error == 'permission') {
            let response = await setApproval(1);

            if (response instanceof Error) {
              dispatch(showToast({ type: "error", message: response.message }));
            } else {
              // Do your work
              if (response.code === 4001) {
                setIsLoading(false);
                dispatch(showToast({ type: 'error', message: 'You Denied the Permission' }))
              } else {
                setIsLoading(true);
                purchaseFractions();
              }
            }
          } else {
            setIsLoading(false)
            dispatch(showToast({ type: "error", message: res?.data?.error }));
          }
        }
      } else {
        setIsLoading(false);
        dispatch(showToast({ type: "error", message: 'Something Went Wrong' }));
      }
    })
  }

  const connectMetaMaskWallet = async () => {
    // setIsLoading(true);
    if (!sign) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x61' }], // chainId must be in hexadecimal numbers
        });
      }
      catch (error) {
        if (error) {
          if (error.code === 4902) {
            await window.ethereum.request({
              method: 'wallet_addEthereumChain',
              params: [
                {
                  chainName: 'BSC Testnet',
                  chainId: '0x61',
                  nativeCurrency: { name: 'BNB', decimals: 18, symbol: 'BNB' },
                  rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545']
                }
              ]
            });

          } else {
            setIsLoading(false);
            dispatch(
              showToast({
                type: "error",
                message: "Metamask Wallet not Found ",
              }))
          }
        }
        return false
      }
      try {
        /**Check metamask install or not  */
        if (Boolean(window.ethereum && window.ethereum.isMetaMask)) {
          try {
            // Will open the MetaMask UI
            await window.ethereum
              .request({ method: "eth_requestAccounts" })
              .then((res) => {
                getReq(`/user/authenticate/${userData['id']}/${res[0]}`)
                  .then((authResponse) => {
                    if (authResponse.status) {
                      window.ethereum
                        .request({
                          method: "personal_sign",
                          params: [`${authResponse.data.data}`, res[0]],
                        })
                        .then((sigRes) => {
                          if (sigRes) {
                            Cookies.set('sign', sigRes)
                            // dispatch(closeWalletModal());
                            postReq(`/user/authenticate`, {
                              address: res[0],
                              signature: sigRes,
                            })
                              .then((authTokenRes) => {
                                if (authTokenRes.status) {
                                  dispatch(
                                    showToast({
                                      type: "success",
                                      message: "Metamask Wallet Connected",
                                    })
                                  );
                                  purchaseFractions();

                                } else {
                                  dispatch(
                                    showToast({
                                      type: "error",
                                      message: authTokenRes.error,
                                    })
                                  );
                                  setIsLoading(false);
                                }
                              })
                              .catch((e) => {
                                setIsLoading(false);
                                dispatch(
                                  showToast({
                                    type: "error",
                                    message: e?.authResponse?.data,
                                  })
                                );
                              });
                          }
                        })
                        .catch((e) => {
                          setIsLoading(false);
                          dispatch(
                            showToast({ type: "error", message: e?.message })
                          );
                        });
                    } else {
                      setIsLoading(false);
                      dispatch(
                        showToast({ type: "error", message: authResponse.error })
                      );
                    }
                  })
                  .catch((e) => {
                    setIsLoading(false);
                    dispatch(
                      showToast({ type: "error", message: e?.authResponse?.data })
                    );
                  });
              });
          } catch (error) {
            console.error(error);
            setIsLoading(false);
          }
        } else {
          dispatch(showToast({ type: "error", message: "Metamask Not Install" }));
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        dispatch(showToast({ type: "error", message: "Unable to Connect with Metamask Wallet" }))
        console.error(error)
      }
    }
    else {
      purchaseFractions();
    }
  };


  useEffect(() => {
    if (id) {
      getReq(`/get-property-details/${id}`).then((res) => {
        if (res.status) {
          const clean = DOMPurify.sanitize(res.data.property_description);
          setCleanDescription(clean);
          setApartment(res.data)
        }
      })
    }
  }, [id])

  useEffect(() => {
    if (!pathname && !user?.token) {
      setLogin(true)
    }
  }, [login])

  useEffect(() => {
    (async () => {
      await connectMetaMaskWallet();
    })()
  }, [])

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className='bg-primary-black'>
        <PageHeader location={'apartments'} title={apartment?.title} />
      </div>
      <div className=" container section-wrapper">
        <div className="bg-primary-black lg:h-[469px] tablet:h-[350px] h-[280px] relative">
          <img className='absolute-img' src={apartment?.featured_image ? `${ENV.BASE_URL}/property_image/${apartment?.featured_image}` : "/images/dummy.jpg"} alt="" />
        </div>
        <div className="mt-[10px] bg-primary-background2 py-10 px-4 tablet:px-14  gap-8 grid lg:grid-cols-6 tablet:grid-cols-3 grid-cols-2 place-items-center">
          <div className="">
            <h4 className='text-6xl font-teko text-primary-main'>{apartment?.fraction_price ? apartment?.fraction_price : 0}</h4>
            <p className='uppercase font-teko font-normal text-primary-gray'>FRACTION PRICE</p>
          </div>
          <div className="">
            <h4 className='text-6xl font-teko text-primary-main'>{apartment?.max_fraction_buy ? apartment?.max_fraction_buy : 0}
              {/* <sup>TH</sup> */}
            </h4>
            <p className='uppercase font-teko font-normal text-primary-gray'>Max Fraction</p>
          </div>
          <div className="">
            <h4 className='text-6xl font-teko text-primary-main'>{apartment?.total_number_of_fraction ? apartment?.total_number_of_fraction : 0}</h4>
            <p className='uppercase font-teko font-normal text-primary-gray'>Total Fraction</p>
          </div>
          <div className="">
            <h4 className='text-6xl font-teko text-primary-main'>{apartment?.estimated_appreciation ? apartment?.estimated_appreciation : 0}</h4>
            <p className='uppercase font-teko font-normal text-primary-gray'>Estimated Appreciation</p>
          </div>
          <div className="">
            <h4 className='text-6xl font-teko text-primary-main'>{apartment?.rental_returns ? apartment?.rental_returns : 0}</h4>
            <p className='uppercase font-teko font-normal text-primary-gray'>Rental Return</p>
          </div>
          <div className="">
            <h4 className='text-6xl font-teko text-primary-main'>{apartment?.valuation ? apartment?.valuation : 0}</h4>
            <p className='uppercase font-teko font-normal text-primary-gray'>Valuation</p>
          </div>
        </div>
        <div className="py-[60px] border-b border-primary-border">
          <h4 className='text-5xl uppercase font-teko font-normal'>Description</h4>
          <p className='mt-2 text-primary-gray leading-[30px]'></p>
          <div className='mt-2 text-primary-gray leading-[30px]' style={{ 'whiteSpace': 'pre-wrap' }} dangerouslySetInnerHTML={funcReturnCleanDesc()} />

        </div>
      </div>
      {/* <BookAnAppointment /> */}
      {(login) &&
        <LoginModal login={login} setLogin={handleClosePopup} />
      }
    </>
  )
}

export default PurchaseFraction