import { Modal, ModalBody, ModalContent, ModalOverlay } from '@chakra-ui/react';
import Loader from '@components/Loader';
import { icons } from '@utils/icons';
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom';

const KycWarning = ({ setKycWarning }) => {
  //state
  const [toggle, setToggle] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const history = useHistory();

  return (
    <>
      <Loader isLoading={isLoading} />
      <Modal
        isCentered
        size={'md'}
        isOpen={true}
        onClose={false}
        motionPreset='slideInBottom'
      >
        <ModalOverlay className='!backdrop-blur-sm' />
        <ModalContent className='mx-2'>
          <ModalBody className='!p-0 rounded-md overflow-hidden'>
            <div className="form flex overflow-hidden   max-w-[500px] w-full text-primary-gray rounded border-primary-border ">
              <div className="flex relative w-full">
                <div className='h-fit w-[100%] flex-shrink-0 p-10 bg-white' >
                  <p className='font-semibold pb-4 text-[17px]'>Buy Fraction Needs Profile Verification </p>
                  <div className='flex justify-between gap-x-4'>
                    <button className="btn-primary h-[52px] lg:max-w-[200px] w-full mt-[10px] center text-[16px]" onClick={() => history.push('/user')} type="submit">Verify Now</button>
                    <button className="btn-primary h-[52px] lg:max-w-[200px] w-full mt-[10px] center text-[16px]" onClick={() => setKycWarning(false)} type="submit">Maybe Later</button>
                  </div>

                </div>

                {/* <form className='h-fit w-[100%] flex-shrink-0 p-12 bg-white' action="">
                  <h3 className='heading-2 text-black text-center relative'>
                    Login
                    <div className="absolute bottom-0 right-0 left-0 mx-auto w-28 h-[2px] bg-primary-main  rounded-md "></div>
                  </h3>
                  <div className="mt-12">
                    <label className='font-teko text-md text-primary-black font-medium' htmlFor="">Email</label>
                    <div className="relative">

                      <span className='ay-center left-1 text-lg'>{icons.user}</span>
                    </div>
                    =
                  </div>
                  <div className="mt-6">
                    <label className='font-teko text-md text-primary-black font-medium' htmlFor="">Password</label>
                    <div className="relative">
                      <input type="password" name='password' value="" placeholder='Enter Your Password' className='pl-10 bg-white h-12 border-solid border-t-0 border-x-0 border-b border-b-primary-gray' />
                      <span className='ay-center left-1 text-lg'>{icons.lock}</span>
                    </div>
                  </div>
                  <div className="mt-12 ">
                    <button type='submit' className='btn2  w-full rounded-full text-2xl font-semibold text-primary-black uppercase bg-primary-background2 hover:bg-primary-main hover:text-white'>Login</button>
                  </div>
                  <div className="center mt-9">
                    <span className='cursor-pointer text-primary-black font-semibold'>Forgot Password</span>
                  </div>
                  <div className="center mt-9">
                    <span onClick={() => setToggle(!toggle)} className='cursor-pointer text-primary-black font-semibold'>Don't have an account? SignUp</span>
                  </div>
                </form> */}
              </div>
            </div>

          </ModalBody>

        </ModalContent>
      </Modal>
    </>
  )
}

export default KycWarning