import ENV from '@utils/env';
import React from 'react'
import { useHistory } from 'react-router-dom'

const ApartmentCard = ({ item, key }) => {
  const history = useHistory();
  return (
    <div className='max-w-[370px] shadow-1 lg:w-[calc(100%/3-30px)] tablet:w-[calc(100%/2-30px)] w-full cursor-pointer' onClick={() => history.push(`/apartments-details/${item?.property_id}`)}>
      <div className='w-full h-[298px] relative'>
        <img className='absolute-img' src={`${ENV.BASE_URL}/property_image/${item?.featured_image}`} alt="" />
        <button className='btn btn-primary absolute bottom-0 z-[10] px-6 font-xs font-thin right-10'>120 M2</button>

      </div>
      <div className='px-8 py-6 relative'>
        <div className='w-6 h-6 bg-white absolute left-10 top-0 rotate-45 -translate-y-1/2'></div>
        <h5 className='text-md uppercase font-teko font-semibold tracking-c'>{item?.title}</h5>
        <p className='font-teko text-primary-gray font-medium text-sm tracking-c'>Price- {item?.fraction_price}</p>
        <p className='font-teko text-primary-gray font-medium text-sm tracking-c'>Explore now</p>

      </div>
    </div>
  )
}

export default ApartmentCard